import React, {useEffect, useState} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Skeleton from "@mui/material/Skeleton";
import SearchIcon from "@mui/icons-material/Search";
import Axios from "axios";
import {headers, mainUrl} from "./../../../../common/common";
import CartModel from "../cart/CartModel";
import {Spinner} from "react-bootstrap";
import {useTranslation} from "react-i18next";

function UsProductBody(props) {
    const [showModel, setShowModel] = useState(false);
    const [pageNo, setPageNo] = React.useState(2);
    const [loadMore, setLoadMore] = React.useState(false);
    const [data, setData] = useState({});
    const {t, i18n} = useTranslation();
    return (
        <>
            <CartModel data={data} setShowModel={setShowModel} showModel={showModel}/>
            <Category item={props} setData={setData} setShowModel={setShowModel}/>
        </>
    );


    function Category(pro) {
        const showEffect = async (c) => {
            document.getElementById(c).style.display = 'block';
            document.getElementById(c + "s").style.display = 'block';
        }
        const hideEffect = async (c) => {
            document.getElementById(c).style.display = 'none';
            document.getElementById(c + "s").style.display = 'none';
        }

        const nextPageLoadList = async (pageNumber = 1) => {
            // e.preventDefault();
            if (pro.item.searchProduct.last_page >= pageNo && loadMore == false) {
                setLoadMore(true);
                setPageNo(1 + pageNo);

                await Axios.post(mainUrl + "getAllProduct?page=" + pageNo, {
                    'sub_category_id': localStorage.getItem("subCategoryId"),
                }, {headers}).then(res => {
                    const lists = res.data;
                    lists.data.map(e => {
                        pro.item.searchProduct.data.push(e);
                    })
                    lists.data = pro.item.searchProduct.data;
                    pro.item.setSearchProduct(lists);
                    setLoadMore(false);
                }).catch((error) => {
                    console.log(error);
                });
            }
        }
        return (
            <>

                {
                    pro.item.searchProductStatus == false &&
                    <>
                        <Row className="col-md-10 offset-1" id="headerrtl"
                             style={{display: 'flex', marginRight: '0', justifyContent: 'center'}}>
                            {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((e, index) => (
                                <Col key={index} className="col-md-3 "
                                     style={{padding: '20px', boxShadow: '0px 0px 3px gray', margin: '20px'}}>
                                    <Row>
                                        <Row style={{paddingBottom: '20px'}}>
                                            <Col>
                                                <Skeleton variant="rectangular" color='red' animation='pulse' style={{}}
                                                          height={350} width={400}/>
                                            </Col>
                                        </Row>
                                        <Row style={{paddingBottom: '20px'}}>
                                            <Col>
                                                <Skeleton variant="rectangular" color='red' animation='pulse' style={{}}
                                                          height={50} width={400}/>
                                            </Col>
                                        </Row>
                                        <Row style={{paddingBottom: '20px'}}>
                                            <Col>
                                                <Skeleton variant="rectangular" color='red' animation='pulse' style={{}}
                                                          height={25} width={300}/>
                                            </Col>
                                        </Row>
                                    </Row>
                                </Col>
                            ))}
                        </Row>
                    </>
                }
                {pro.item.searchProductStatus == true &&
                <>

                    <Row className="col-md-10 offset-1"  id="headerrtl"
                         style={{display: 'flex', marginRight: '0', justifyContent: 'center'}}>
                        {
                            pro.item.searchProduct.data.map((p, index) => (
                                    <Col md={3} key={index}
                                         style={{padding: '20px', boxShadow: '0px 0px 3px gray', margin: '20px',minWidth:'400px'}}>
                                        <Row>
                                            <Col>
                                                {p.image != null &&
                                                <div role='button'
                                                     style={{position: "relative", textAlign: 'center', height: "400px"}}
                                                     onMouseOver={() => showEffect("id" + p.id)}
                                                     onMouseOut={() => hideEffect("id" + p.id)}
                                                     onClick={() => {
                                                         pro.setData(p);
                                                         pro.setShowModel(true);
                                                     }}
                                                >
                                                    <img src={p.image} height='auto' width='auto'
                                                         style={{position: 'relative'}}/>
                                                    <div
                                                        onClick={() => {
                                                            pro.item.setSubCategoryId(p.id);
                                                            pro.item.setSubCategoryPage(true);
                                                        }}
                                                        style={{
                                                            height: "200px",
                                                            width: "200px",
                                                            position: "absolute",
                                                            display: 'block',
                                                            top: '0',
                                                            padding: '5px',
                                                        }} className=""></div>

                                                    <div id={"id" + p.id + "s"} style={{
                                                        width: "100%",
                                                        textAlign: 'center',
                                                        opacity: '0.9',
                                                        position: "absolute",
                                                        bottom: '0',
                                                        padding: '5px',
                                                        fontSize: '20px',
                                                        whiteSpace: "nowrap",
                                                        display: 'none',
                                                        backgroundColor: '#4d8ffa91'
                                                    }} className="text-light ">
                                                        {/*Add To Cart*/}
                                                        {t('addtocart')}
                                                    </div>
                                                    <div id={"id" + p.id} style={{
                                                        width: "50px",
                                                        display: 'none',
                                                        position: "absolute",
                                                        right: '10px',
                                                        bottom: '50px',
                                                        fontSize: '20px',
                                                        index: '1000'
                                                    }} className=" text-light">
                                                        <SearchIcon
                                                            onClick={() => {
                                                                pro.setData(p);
                                                                pro.setShowModel(true);
                                                            }}
                                                            style={{
                                                                backgroundColor: 'rgba(255, 255, 255, 1)',
                                                                borderRadius: '45%'
                                                            }} color='success' fontSize='medium'/><br/>
                                                    </div>

                                                </div>
                                                }
                                                {p.image == null &&
                                                <Skeleton variant="rectangular" color='red'
                                                          animation='pulse' height={200}/>
                                                }
                                            </Col>
                                        </Row>
                                        <Row className="p-xl-1">
                                            <Col>
                                                {p.name != null &&
                                                <span
                                                    className="text-info  h6 text-capitalize">{p.name}</span>
                                                }
                                                {p.name == null &&
                                                <Skeleton variant="rectangular" color='red'
                                                          animation='pulse' height={40}/>
                                                }
                                            </Col>
                                        </Row>
                                        <Row className="p-xl-1">
                                            <Col>
                                                {p.unit_name != null &&
                                                <span
                                                    className="text-info  h6 text-capitalize">1 {p.unit_name}</span>
                                                }
                                                {p.unit_name == null &&
                                                <Skeleton variant="rectangular" color='red'
                                                          animation='pulse' height={30}/>
                                                }
                                            </Col>
                                        </Row>
                                        <Row className="p-xl-1">
                                            <Col>
                                                {p.shipping_info != null &&
                                                <span
                                                    className="text-info text-capitalize">1 {p.shipping_info}</span>
                                                }
                                                {p.shipping_info == null &&
                                                <Skeleton variant="rectangular" color='red'
                                                          animation='pulse' height={30}/>
                                                }
                                            </Col>
                                        </Row>
                                        <Row className="p-xl-1">
                                            <Col>
                                                {p.amount != null &&
                                                <div>
                                                    <span className="text-default  text-decoration-line-through fst-italic"> {p.p_amount}</span>
                                                    <span className="text-default h6 text-capitalize"> {p.amount}</span>
                                                    <span className="text-default h6 text-capitalize"> {p.cur_name}</span>
                                                </div>
                                                }
                                                {p.amount == null &&
                                                <Skeleton variant="rectangular" color='red'
                                                          animation='pulse' height={30}/>
                                                }
                                            </Col>
                                        </Row>
                                    </Col>
                                )
                            )
                        }

                    </Row>
                    <div className="page-content-center">
                        {loadMore === true ? (
                                <div className="text-center">
                                    <Spinner animation="border" variant="info"/>
                                </div>
                            ) :
                            (pro.item.searchProduct.last_page >= pageNo ?
                                <button className="btn  bg-light text-info" style={{whiteSpace: 'nowrap'}}
                                        title="Load More"
                                        onClick={(pageNumber => {
                                            nextPageLoadList(pageNumber);
                                        })}>
                                    {t('viewmore')}
                                    {/*<span className="nc-icon nc-simple-add text-info"/>*/}
                                </button>
                                : "")}
                    </div>
                </>
                }
            </>
        );
    }
}

export default UsProductBody;
