import React, {useState} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Box from "@mui/material/Box";
import {mainUrl, toBase64} from "../../../../common/common";
import Axios from "axios";
import toast from "react-hot-toast";
import {useTranslation} from "react-i18next";

function Carts(props) {
    const {t, i18n} = useTranslation();
    const [quantity, setQuantity] = useState(0);

    const notifyError = (message) => toast.error(message, {
        duration: 6000,
    });
    const notifySuccess = (message) => toast.success(message, {
        duration: 6000,
    });
    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Header': '*',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
    const addCart = async () => {
        // e.preventDefault();
        if(localStorage.getItem("token")!=null) {
            await Axios.post(mainUrl + "addCart", {
                quantity: quantity,
                product_id: props.pro.id,
                token: JSON.parse(localStorage.getItem("token")),
            }, {headers}).then(res => {
                if(localStorage.getItem('cart')!=null){
                    localStorage.setItem('cart',Number(localStorage.getItem('cart'))+1);
                }else{
                    localStorage.setItem('cart',1);
                }
                // notifySuccess(' Added Successfully.');
                notifySuccess(t('addmessage'));
            }).catch(function (error) {
                notifyError(error.response.data.message);
            });
        }else{
            // alert('Please Login');
            alert(t('pllogin'));
        }
    };
    return (
        <>
            <Row>
                <Col md={5} style={{margin: 'auto', textAlign: 'center'}}>
                    <img src={props.pro.image}/>
                </Col>
                <Col md={{span: 7}} className="justify-content-lg-end">

                    <Row>
                        <Col><span className='h1 text-capitalize'>{props.pro.name}</span></Col>
                    </Row>
                    <Row>
                        <Col>
                           <span
                               className="text-default h5 text-capitalize">1 {props.pro.unit_name}</span>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <span className="h6 text-decoration-line-through">{props.pro.p_amount}</span>
                            <span className='h5 text-uppercase'>  {props.pro.amount} {props.pro.cur_name} </span>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Row>
                                <Col>
                                    <Row>
                                        <Col style={{display: 'flex', bottom: '0'}}>
                                            <div style={{border: '1px solid gray'}}>
                                                <KeyboardArrowLeftIcon fontSize='large' role='button'
                                                                       onClick={() => {
                                                                           if (quantity != 0) {
                                                                               setQuantity(quantity - 1);
                                                                           }
                                                                       }}/>
                                            </div>
                                            <div
                                                style={{width: '100px', textAlign: 'center', border: '1px solid gray'}}>
                                                {quantity}
                                            </div>
                                            <div style={{border: '1px solid gray'}}>
                                                <KeyboardArrowRightIcon fontSize='large' role='button'
                                                                        onClick={() => {
                                                                            setQuantity(quantity + 1);
                                                                        }}/>
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            <div role='button' style={{
                                                width: '175px',
                                                textAlign: 'center',
                                                border: '1px solid gray',
                                                height: '50',
                                                backgroundColor:'#1daae2',
                                                padding: '10px'
                                            }}
                                                 className='h6 text-light' onClick={() => addCart()}>
                                                {/*Add to Cart*/}
                                                {t('addtocart')}
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <hr/>
                    <Row>
                        <Col>
                            <span className='h6 text-capitalize'>
                                {/*Product Detail*/}
                                {t('productdtail')}
                            </span>

                            <Box
                                sx={{
                                    flexGrow: 1,

                                    display: 'flex',
                                    height: '450px',
                                    overflowY: 'auto',
                                    marginBottom: '50px'
                                }}
                            >
                                <div style={{}}>
                                    <div dangerouslySetInnerHTML={{__html: props.pro.description}}></div>
                                </div>
                            </Box>
                        </Col>
                    </Row>
                </Col>

            </Row>
        </>
    );
}

export default Carts;
