import React, {useEffect, useState} from "react";
import Col from "react-bootstrap/Col";
import orderLogo from "../../../../assets/img/logo.png";
import Row from "react-bootstrap/Row";
import Axios from "axios";
import {headers, mainUrl} from "../../../../common/common";
import {useTranslation} from "react-i18next";
import {Input} from "reactstrap";
import PhoneInput from "react-phone-number-input";
import {Button} from "react-bootstrap";
import toast from "react-hot-toast";

function HourSupport(props) {
    const [data, setData] = useState({});
    useEffect(() => {
        setData(props.data);
    }, []);


    const submitForm = async (e) => {
        e.preventDefault();
        const f = e.target;
         {
            await Axios.post(mainUrl + "addMessage", {
                id: f.id.value,
                name: f.name.value,
                email: f.email.value,
                phone: f.phone.value,
                subject: f.subject.value,
                help: f.help.value,
            }, {headers}).then(res => {
                notifySuccess("Successfully Submitted!");
                document.getElementById("id").value = res.data;
            }).catch(function (error) {
                notifyError(error.response.data.error);
            });
            document.getElementById("submit").disabled = false;
        }
    };
    const notifyError = (message) => toast.error(message, {
        duration: 6000,
    });
    const notifySuccess = (message) => toast.success(message, {
        duration: 6000,
    });

    const {t, i18n} = useTranslation();
    return (
        <>
            {
                <>
                    <div className="p-xxl-2 offset-2 col-md-8 fs-6 mt-5" style={{margin: '0 auto', minHeight: '800px'}}>
                        <h4 style={{color: '#0141a8'}}>
                            {t('HourSupport')}
                        </h4>
                        <div style={{marginBottom:'50px'}}>
                            <Row>
                                <Col>
                                    <div className="cart border border-1 text-center "
                                         style={{minHeight: '150px', paddingTop: '20px'}}>
                                        <i className="fa fa-envelope p-2 " style={{fontSize: '30px'}}/><br/>
                                        {t('ContactEmail')}
                                        <br/>
                                        {data.email}
                                    </div>
                                </Col>

                                <Col>
                                    <div className="cart border border-1 text-center"
                                         style={{minHeight: '150px', paddingTop: '20px'}}>
                                        <i className="fas fa-map-marker-alt p-2 " style={{fontSize: '30px'}}></i><br/>
                                        {t('Address')}
                                        <br/>
                                        {data.address}
                                    </div>
                                </Col>

                                <Col>
                                    <div className="cart border border-1 text-center"
                                         style={{minHeight: '150px', paddingTop: '20px'}}>
                                        <i className="fa fa-phone p-2 " style={{fontSize: '30px'}}/><br/>
                                        {t('ContactNumber')}
                                        <br/>
                                        {data.phone}
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <form onSubmit={(e) => {
                            document.getElementById("submit").disabled = true;
                            submitForm(e);
                        }}>
                            <Row>
                                <Col className="col-md-12 pr-3 pl-3">
                                    <Row>
                                        <Col md="12">
                                            <input type="hidden" name="id" id="id"/>
                                            <div className="form-group mb-3">
                                                <label>{t('YourName')}<span className="text text-danger">*</span></label>
                                                <Input required className="w-100" name="name" id="name" required
                                                       title={t('YourName')} type="text"/>
                                            </div>
                                            <div className="form-group mb-3">
                                                <label>{t('EmailAddress')}<span className="text text-danger">*</span></label>
                                                <Input required className="w-100" name="email" id="email" required
                                                       title={t('EmailAddress')} type="email"/>
                                            </div>
                                            <div className="form-group mb-3">
                                                <label>{t('PhoneNumber')}<span className="text text-danger">*</span></label>
                                                <Input required className="w-100" name="phone" id="phone" required
                                                       title={t('PhoneNumber')} type="number"/>
                                            </div>
                                            <div className="form-group mb-3">
                                                <label>{t('Subject')}<span className="text text-danger">*</span></label>
                                                <Input required className="w-100" name="subject" id="subject" required
                                                       title={t('Subject')} type="text"/>
                                            </div>

                                            <div className="form-group mb-3">
                                                <label>{t('Help')}<span className="text text-danger">*</span></label>
                                                <textarea required className="form-control w-100 " style={{height:'300px'}} name="help" id="help" required
                                                       title={t('Help')} type="text">
                                                </textarea>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="">
                                    <Button style={{backgroundColor: 'rgb(29 169 225)',}} id="submit"
                                            type="submit">
                                        Send Message
                                    </Button>
                                </Col>
                            </Row>
                        </form>
                    </div>
                </>
            }
        </>
    );
}

export default HourSupport;
