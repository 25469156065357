import {Modal} from "react-bootstrap";
import CloseIcon from "@mui/icons-material/Close";
import WashLists from "./WashLists";
import React from "react";
import {useTranslation} from "react-i18next";

function WashListModel(props) {
    const {t, i18n} = useTranslation();
    return (
        <>
            <Modal show={props.showWashListModel} backdrop="static" size="lg" onHide={() => props.setShowWashListModel(false)}
                   style={{ top: '10vh'}}>
                <Modal.Header>
                    <div  style={{ right: '10px',display: 'flex',width:'100%' }}>
                        <div style={{whiteSpace:'nowrap'}}>
                            <h2>{t('cart')}</h2>
                        </div>
                        <div id="left" style={{textAlign: 'right', width: '100%'}}>
                            <CloseIcon fontSize='large' role="button"
                                       style={{backgroundColor: 'rgb(255,0,0)', borderRadius: '50%', right: '0',color:'rgb(255,255,266)'}}
                                       onClick={() => props.setShowWashListModel(false)}/>
                        </div>
                    </div>

                </Modal.Header>
                <Modal.Body>
                    <WashLists data={props.data} setData={props.setData}/>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default WashListModel;
