import React, {useEffect, useState} from "react";
import {Button, Col, Container, FormGroup, Row} from "react-bootstrap";
import playstore from "./../../assets/img/playstore.png";
import appstore from "./../../assets/img/appstore.png";

import wadan from "./../../assets/img/wadan.png";
import unhcr from "./../../assets/img/unhcrimage.jpg";

import contactus from "./../../assets/img/contact-us.svg";
import delivery from "./../../assets/img/delivery.svg";
import originguarantee from "./../../assets/img/origin-guarantee.svg";
import paymentterms from "./../../assets/img/payment-terms.svg";
import returnpolicy from "./../../assets/img/return-policy.svg";

import unicef from "./../../assets/img/unicef-logo.png";

import BusinessIcon from '@mui/icons-material/Business';
import PermPhoneMsgIcon from '@mui/icons-material/PermPhoneMsg';
import EmailIcon from '@mui/icons-material/Email';
import CopyrightIcon from '@mui/icons-material/Copyright';
import {NavLink} from "react-router-dom";
import {maxHeight, padding} from "@mui/system";
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import "./Footer.css"
import {useTranslation} from "react-i18next";

function Footer(props) {


    const {t, i18n} = useTranslation();
    const year = new Date().getFullYear();
    return (
        <footer dir="ltr"
                style={{marginBottom: "0px", direction: "ltr!important", top: 'auto', bottom: "0px", width: "100%"}}>

            <hr/>
            <Row style={{textAlign: 'left'}} className='margin-check'>
                <Col md={{offset: 2,span:8}} style={{padding: '20px'}}>
                    <div className='row margin-check'>
                        <div className="service-item col">
                            <NavLink title={t('ExpressDelivery')} to='/ExpressDelivery'>
                                <div style={{height:'150px',display: 'flex',justifyContent: 'center', alignItems: 'center'}}>
                                   <img style={{maxHeight:'150px',maxWidth:'150px'}} width='auto' height='auto' src={delivery}/>
                                </div>
                                <p>{t('ExpressDelivery')}</p>
                            </NavLink>
                        </div>
                        <div className="service-item col">
                            <NavLink title={t('ExpressDelivery')} to='/HourSupport'>
                                <div style={{height:'150px',display: 'flex',justifyContent: 'center', alignItems: 'center'}}>
                                    <img style={{maxHeight:'150px',maxWidth:'150px'}} width='auto' height='auto' src={contactus}/>
                                </div>
                                <p>{t('HourSupport')}</p>
                            </NavLink>
                        </div>

                        <div className="service-item col">
                            <NavLink title={t('ExpressDelivery')} to='/SitePayment'>
                                <div style={{height:'150px',display: 'flex',justifyContent: 'center', alignItems: 'center'}}>
                                    <img style={{maxHeight:'150px',maxWidth:'150px'}} width='auto' height='auto' src={paymentterms}/>
                                </div>
                                <p>{t('SitePayment')}</p>
                            </NavLink>
                        </div>

                        <div className="service-item col">
                            <NavLink title={t('ExpressDelivery')} to='/BackGuarantee'>
                                <div style={{height:'150px',display: 'flex',justifyContent: 'center', alignItems: 'center'}}>
                                    <img style={{maxHeight:'150px',maxWidth:'150px'}} width='auto' height='auto' src={returnpolicy}/>
                                </div>
                                <p>{t('BackGuarantee')}</p>
                            </NavLink>
                        </div>

                        <div className="service-item col">
                            <NavLink title={t('ExpressDelivery')} to='/GuaranteeGoods'>
                                <div style={{height:'150px',display: 'flex',justifyContent: 'center', alignItems: 'center'}}>
                                    <img style={{maxHeight:'150px',maxWidth:'150px'}} width='auto' height='auto' src={originguarantee}/>
                                </div>
                                <p>{t('GuaranteeGoods')}</p>
                            </NavLink>
                        </div>

                    </div>
                </Col>
                {/*<Col md={{offset:2}}></Col>*/}
            </Row>
            <Container style={{
                backgroundColor: 'rgb(29 169 225)',
                color: 'white', margin: '0', maxWidth: '100%'
            }}>
                <Row style={{textAlign: 'left'}} className='margin-check'>
                    <Col md={{span: 4, offset: 2}} style={{padding: '20px'}}>
                        {/*<h3>KNOW IT ALL FIRST!</h3>*/}
                        {/*Never Miss Anything From Order By Signing Up To Our Newsletter.*/}
                    </Col>
                    <Col md={{span: 4}} style={{padding: '20px', textAlign: 'right'}}>
                        {/*<FormGroup style={{display: 'inline-flex'}}>*/}
                        {/*  <Input required style={{minWidth: '20vw'}} name="phone_number" id="phone_number" required*/}
                        {/*         placeholder="Enter your whatsapp number"*/}
                        {/*         title="name" type="text"/>*/}
                        {/*  <Button variant="success">Subscribe </Button>*/}
                        {/*</FormGroup>*/}
                    </Col>
                </Row>
                <Row style={{display: 'inline-flex!important', textAlign: 'left'}}>
                    <Col md={{span: 3, offset: 2}} style={{padding: '20px'}}>
                        <img src={props.data.logo} height='100' width='auto'/>
                        <br/>
                        <Row style={{paddingTop: '20px'}}>
                            <Col md={12}>
                                {/*Fallow us :*/}
                                {t('fallowus')}
                            </Col>
                            <Col md={12} style={{display: 'flex'}}>
                                <a title="Facebook" id="Facebook" href={props.data.facebook_link} target="__blank"
                                   style={{borderRadius: "5%"}}>
                                    <FacebookIcon style={{color: "white"}}/>
                                </a>
                                <a title="Instagram" id="Instagram" href={props.data.instagram_link} target="__blank"
                                   style={{borderRadius: "5%"}}>
                                    <InstagramIcon style={{color: "white"}}/>
                                </a>
                                <a title="Twitter" id="Twitter" href={props.data.twitter} target="__blank"
                                   style={{borderRadius: "5%"}}>
                                    <TwitterIcon style={{color: "white"}}/>
                                </a>
                                <a title="YouTube" id="YouTube" href={props.data.youtube_link} target="__blank"
                                   style={{borderRadius: "5%"}}>
                                    <YouTubeIcon style={{color: "white"}}/>
                                </a>
                                <a title="TikTok" id="TikTok" href={props.data.tiktok_link} target="__blank"
                                   style={{borderRadius: "5%"}}>
                                    <MusicNoteIcon style={{color: "white"}}/>
                                </a>
                                <a title="LinkedIn" id="LinkedIn" href={props.data.linkedin_link} target="__blank"
                                   style={{borderRadius: "5%"}}>
                                    <LinkedInIcon style={{color: "white"}}/>
                                </a>
                            </Col>
                            <Col style={{width: '100%'}}></Col>
                        </Row>

                    </Col>
                    <Col md={{span: 3}} style={{padding: '40px', float: 'right', display: 'block'}}>
                        <a href={props.data.play_store_link} target="__blank">
                            <img src={playstore} width='150' height='auto'/>
                        </a>
                        <a href={props.data.app_store_link} target="__blank">
                            <img src={appstore} width='150' height='auto'/>
                        </a>
                    </Col>
                    <Col md={{span: 3}} style={{padding: '20px', float: 'right'}}>
                        <Row>
                            <Col>
                                {/*STORE INFORMATION*/}
                                {t('storeinfo')}
                            </Col>
                        </Row>
                        <Row>
                            <Col><BusinessIcon style={{color: 'white'}}/> {props.data.address}</Col>
                        </Row>
                        <Row> <Col><PermPhoneMsgIcon style={{color: 'white'}}/> {props.data.phone}</Col>
                        </Row>
                        <Row><Col><EmailIcon style={{color: 'white'}}/> {props.data.email}</Col>
                        </Row>

                    </Col>
                </Row>
                <Row>
                    <Col md={{span: 4, offset: 4}} style={{padding: '20px'}}>
                        <Row>
                            <Col style={{width: '50%!important'}}></Col>
                            {props.routes.map((prop, key) => {
                                if (prop.hide != "true")
                                    return (
                                        <Col key={key} className=" "
                                             style={{textAlign: 'center', whiteSpace: 'nowrap'}}
                                        >
                                            <NavLink key={key} title={prop.title}
                                                     style={{textAlign: 'center', whiteSpace: 'nowrap'}}
                                                     to={prop.layout + prop.path}
                                                     className="nav-link">
                                        <span>
                                              {t(prop.name)}
                                            {/*{prop.name}*/}
                                        </span>
                                            </NavLink>
                                        </Col>
                                    );
                            })}

                            <Col style={{width: '50%!important'}}></Col>
                        </Row>
                    </Col>

                </Row>
                {/*<Row style={{display: 'flex',justifyContent: 'center'}}>*/}
                {/*    <Col md={2} style={{whiteSpace:'nowrap'}}>*/}
                {/*        /!*<img src={unicef} style={{width: 'auto',height:'100px',backgroundColor:'#fff' ,margin:'10px',padding:'10px'}}/>*!/*/}
                {/*        <a href="https://wadan.org/" target="_blank" title="Wadan"><img src={wadan} style={{width: 'auto',height:'100px'  ,margin:'10px',padding:'10px'}}/></a>*/}
                {/*        <a href="https://www.unhcr.org/" target="_blank"  title="UNHCR"><img src={unhcr} style={{width: 'auto',height:'100px'  ,margin:'10px',padding:'10px'}}/></a>*/}
                {/*        /!*<img src={unhcr}  style={{width: 'auto',height:'100px' ,margin:'10px',padding:'10px'}}/>*!/*/}
                {/*    </Col>*/}

                {/*</Row>*/}
                <Row style={{padding: "20px"}}>
                    <Col md={{span: 8, offset: 2}}>
                        <hr/>
                    </Col>
                    <Col md={{span: 4, offset: 2}}>

                         <span style={{fontSize: '9pt', textAlign: 'center'}}>
                                             Copyright © 2023 &nbsp;
                             <a className='text-light' target='_blank' title='United Nations High Commissioner for Refugees Organization' href='https://www.unhcr.org/'> UNHCR</a> -
                                        <a className='text-light' target='_blank' href='https://wadan.org/' title='Welfare Association for the Development of Afghanistan Organization'> WADAN</a> –
                                        <a className='text-light' target='_blank' title='Madein Afghanistan' href='http://https://mdf.afghaninvest.co/'> madein-afghanistan.com</a>
                             &nbsp;All Rights Reserved.
                                          </span>

                    </Col>
                    <Col md={{span: 4}} style={{textAlign: "right", color: "black"}}>
                        <NavLink title="FAQ" style={{textAlign: 'center', color: "black", whiteSpace: 'nowrap'}}
                                 to="/TermsService">
                            {/*Terms of Service*/}
                            {t('termservice')}
                        </NavLink>
                        &nbsp;|&nbsp;
                        <NavLink title="FAQ" style={{textAlign: 'center', color: "black", whiteSpace: 'nowrap'}}
                                 to="/PrivacyPolicy">
                            {/*Privacy Policy*/}

                            {t('privacypolicy')}
                        </NavLink>
                        &nbsp;|&nbsp;
                        <NavLink title="FAQ" style={{textAlign: 'center', color: "black", whiteSpace: 'nowrap'}}
                                 to="/FAQ">
                            {/*FAQ*/}
                            {t('faq')}
                        </NavLink>

                    </Col>
                </Row>
            </Container>
        </footer>
    );
}


export default Footer;
