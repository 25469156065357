import React from "react";

import CloseIcon from "@mui/icons-material/Close";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

import Container from 'react-bootstrap/Container';
// import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import {Col, Row} from "react-bootstrap";
import Button from "@mui/material/Button";
import {ShoppingCart} from "@mui/icons-material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import FavoriteIcon from "@mui/icons-material/Favorite";
import PersonIcon from "@mui/icons-material/Person";
import AccountCircle from "@mui/icons-material/AccountCircle";
import LoginIcon from "@mui/icons-material/Login";
import Logout from "@mui/icons-material/Logout";
import {useHistory} from "react-router";
import toast, {Toaster} from "react-hot-toast";
import Axios from "axios";
import {mainUrl} from "../../../common/common";
import RegisterModel from "../body/customer/register/RegisterModel";
import WashListModel from "../body/washlist/WashListModel";
import LoginModel from "../body/customer/login/LoginModel";
import {NavLink} from "react-router-dom";
import {padding} from "@mui/system";

import {useTranslation} from 'react-i18next';
import SearchIcon from '@mui/icons-material/Search';
import DropdownToggle from "react-bootstrap/DropdownToggle";

function Head(props) {

    const {t, i18n} = useTranslation();

    //Start Progress
    const [cartCount, setCartCount] = React.useState(0);
    const [progress, setProgress] = React.useState(0);
    const [progressStatus, setProgressStatus] = React.useState(true);
    const [buffer, setBuffer] = React.useState(10);
    const [showRegisterModel, setShowRegisterModel] = React.useState(false);
    const [showLoginModel, setShowLoginModel] = React.useState(false);
    const [showWashListModel, setShowWashListModel] = React.useState(false);
    const [data, setData] = React.useState([]);

    const progressRef = React.useRef(() => {
    });

    const navigate = useHistory();

    const search = async () => {
        const value = document.getElementById("search").value;
        if (value != "") {
            localStorage.setItem('search', value)
            navigate.push("/search");
        }
    }

    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Header': '*',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
    React.useEffect(() => {
        setInterval(() => {
            setCartCount(Number(localStorage.getItem('cart')));
        }, 100);
    }, [])
    React.useEffect(() => {
        progressRef.current = () => {
            if (progress > 100) {
                setProgressStatus(false);
            } else {
                const diff = Math.random() * 10;
                const diff2 = Math.random() * 10;
                setProgress(progress + diff);
                setBuffer(progress + diff + diff2);
            }
        };
    });
    React.useEffect(() => {
        const timer = setInterval(() => {
            progressRef.current();
        }, 100);
        return () => {
            clearInterval(timer);
        };
    }, []);

    //End  Progress

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorElLanguage, setAnchorElLanguage] = React.useState(null);
    const [anchorEla, setAnchorEla] = React.useState(null);
    const openLanguage = Boolean(anchorElLanguage);
    const open = Boolean(anchorEl);
    const opena = Boolean(anchorEla);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClicka = (event) => {
        setAnchorEla(event.currentTarget);
    };
    const handleClickLanguage = (event) => {
        setAnchorElLanguage(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleClosea = () => {
        setAnchorEla(null);
    };
    const handleCloseLanguage = () => {
        setAnchorElLanguage(null);
    };
    const notifyError = (message) => toast.error(message, {
        duration: 6000,
    });
    const notifySuccess = (message) => toast.success(message, {
        duration: 6000,
    });
    const closeAll=()=>{
        handleClose();
        handleClosea();
        handleCloseLanguage(); //
    }
    const whatsapp = "https://wa.me/" + props.data.whatsapp_link+"?text=سلام";
    const getCart = async () => {
        if (localStorage.getItem("token") != null) {
            await Axios.post(mainUrl + "getCart", {
                token: JSON.parse(localStorage.getItem("token")),
                'lang':localStorage.getItem("lang")
            }, {headers}).then(res => {
                setData(res.data);
                setShowWashListModel(true);
            }).catch(function (error) {
                notifyError(error.response.data.message);
            });
        } else {
            alert('Please Login');
        }
    };
    const showSearch = () => {
        document.getElementById('searchblock').style.border = "1px solid gray";
        document.getElementById('search').style.display = "block";
        document.getElementById('closeButton').style.display = "block";
        document.getElementById('searchButton').style.display = "none";
    }
    const hideSearch = () => {
        document.getElementById('searchblock').style.border = "none";
        document.getElementById('search').style.display = "none"
        document.getElementById('closeButton').style.display = "none"
        document.getElementById('searchButton').style.display = "block"
    }
    const selectLanguage = (language) =>{
        // localStorage.setItem('dir',language);
        localStorage.setItem('lang',language);
        localStorage.setItem('dir',language);
        setAnchorElLanguage(null);
        window.location.reload();
    }
    return (
        <>
            <Toaster position="top-right" reverseOrder={false}/>
            <RegisterModel setShowRegisterModel={setShowRegisterModel} showRegisterModel={showRegisterModel}/>
            <WashListModel setShowWashListModel={setShowWashListModel} showWashListModel={showWashListModel}
                           data={data}
                           setData={setData}/>
            <LoginModel setShowLoginModel={setShowLoginModel} showLoginModel={showLoginModel}/>

            {/*<Row style={{width:"100%"}} className="d-none  ">*/}
            {/*    <Col  style={{paddingLeft:"50px",paddingRight:"50px"}}>*/}
            {/*        <img src={props.data.logo} height='50' width='auto'/>*/}
            {/*    </Col>*/}
            {/*    <Col >*/}
            {/*        <Col id="headerrtl" style={{display: 'flex',justifyContent:'right'}}>*/}
            {/*            <div id="searchblock" style={{display: 'flex', borderRadius: '10px',marginTop:'5px'}} title={t('searchproduct')}>*/}
            {/*                <input placeholder={t('searchproduct')} type="text" id="search"  title={t('searchproduct')}*/}
            {/*                       style={{*/}
            {/*                           border: 'none',*/}
            {/*                           display: 'none',*/}
            {/*                           minWidth: "200px",*/}
            {/*                           padding: "5px",*/}
            {/*                           width: "30vw", borderRadius: '10px',*/}
            {/*                           maxHeight: "30px!important"*/}
            {/*                           ,*/}
            {/*                           fontSize: "10pt"*/}
            {/*                       }}*/}
            {/*                       onKeyUp={(e) => {*/}
            {/*                           if (e.code == "Enter") {*/}
            {/*                               search();*/}
            {/*                           }*/}
            {/*                       }}/>*/}
            {/*                <span role="button" id="searchButton" onClick={() => showSearch()}><SearchIcon*/}
            {/*                    style={{fontSize: "13pt"}} title="Search Product"/></span>*/}
            {/*                <span role="button" style={{display: "none"}} id="closeButton"*/}
            {/*                      onClick={() => hideSearch()}><CloseIcon*/}
            {/*                    style={{fontSize: "13pt"}}/></span>*/}
            {/*            </div>*/}

            {/*            /!**!/*/}
            {/*            <div>*/}
            {/*                <Button*/}
            {/*                    style={{fontSize: '13pt',paddingTop:'10px', color: "black"}}*/}
            {/*                    variant="text"*/}
            {/*                    title="Language"*/}
            {/*                    id="demo-positioned-buttona"*/}
            {/*                    aria-controls={openLanguage ? 'demo-positioned-menus' : undefined}*/}
            {/*                    aria-haspopup="true"*/}
            {/*                    aria-expanded={openLanguage ? 'true' : undefined}*/}
            {/*                    onClick={handleClickLanguage}>*/}
            {/*                    <i className="nc-icon nc-world-2"> </i>*/}
            {/*                </Button>*/}
            {/*                <Menu*/}
            {/*                    id="demo-positioned-menus"*/}
            {/*                    aria-labelledby="demo-positioned-buttona"*/}
            {/*                    anchorEl={anchorElLanguage}*/}
            {/*                    open={openLanguage}*/}
            {/*                    style={{marginTop: '30px'}}*/}
            {/*                    onClose={handleCloseLanguage}*/}
            {/*                    anchorOrigin={{*/}
            {/*                        vertical: 'top',*/}
            {/*                        horizontal: 'left',*/}
            {/*                    }}*/}
            {/*                    transformOrigin={{*/}
            {/*                        vertical: 'top',*/}
            {/*                        horizontal: 'left',*/}
            {/*                    }}*/}
            {/*                >*/}
            {/*                    <MenuItem style={{fontSize: '10pt', color: "black"}} onClick={()=>{selectLanguage('fa')}} >*/}
            {/*                        فارسی*/}
            {/*                    </MenuItem>*/}
            {/*                    <MenuItem style={{fontSize: '10pt', color: "black"}}  onClick={()=>{selectLanguage('pa')}} >*/}
            {/*                        پشتو*/}
            {/*                    </MenuItem>*/}
            {/*                    <MenuItem style={{fontSize: '10pt', color: "black"}}  onClick={()=>{selectLanguage('en')}}>*/}
            {/*                        English*/}
            {/*                    </MenuItem>*/}
            {/*                </Menu>*/}
            {/*            </div>*/}

            {/*            <Button*/}
            {/*                style={{fontSize: '10pt', color: "black"}}*/}
            {/*                variant="text"*/}
            {/*                title="Cart"*/}
            {/*                id="demo-positioned-buttona"*/}
            {/*                aria-controls={opena ? 'demo-positioned-menus' : undefined}*/}
            {/*                aria-haspopup="true"*/}
            {/*                aria-expanded={opena ? 'true' : undefined}*/}
            {/*                onClick={handleClicka}>*/}
            {/*                <ShoppingCart style={{fontSize: '10pt', color: "black"}}/>*/}
            {/*                {t('cart')}*/}
            {/*                /!*Cart*!/*/}
            {/*            </Button>*/}
            {/*            <Menu*/}
            {/*                id="demo-positioned-menus"*/}
            {/*                aria-labelledby="demo-positioned-buttona"*/}
            {/*                anchorEl={anchorEla}*/}
            {/*                open={opena}*/}
            {/*                style={{marginTop: '30px'}}*/}
            {/*                onClose={handleClosea}*/}
            {/*                anchorOrigin={{*/}
            {/*                    vertical: 'top',*/}
            {/*                    horizontal: 'left',*/}
            {/*                }}*/}
            {/*                transformOrigin={{*/}
            {/*                    vertical: 'top',*/}
            {/*                    horizontal: 'left',*/}
            {/*                }}*/}
            {/*            >*/}
            {/*                <MenuItem style={{fontSize: '10pt', color: "black"}} onClick={() => {*/}
            {/*                    closeAll();*/}
            {/*                    setShowLoginModel(false);*/}
            {/*                    setShowRegisterModel(false);*/}
            {/*                    getCart();*/}
            {/*                }}>*/}
            {/*                    <FavoriteIcon*/}
            {/*                        style={{fontSize: '10pt', color: "black"}}/> &nbsp; {t('cart')}*/}
            {/*                        /!*Cart *!/*/}
            {/*                    &nbsp;&nbsp;*/}
            {/*                    <div style={{*/}
            {/*                        display: 'flex',*/}
            {/*                        backgroundColor: '#1da9e1',*/}
            {/*                        color: '#fff',*/}
            {/*                        borderRadius: '10px 10px 10px 10px'*/}
            {/*                    }}> &nbsp; {cartCount} &nbsp;</div>*/}
            {/*                </MenuItem>*/}
            {/*            </Menu>*/}
            {/*            <Button*/}
            {/*                variant="text"*/}
            {/*                title={t('account')}*/}
            {/*                id="demo-positioned-button"*/}
            {/*                aria-controls={open ? 'demo-positioned-menu' : undefined}*/}
            {/*                aria-haspopup="true"*/}
            {/*                aria-expanded={open ? 'true' : undefined}*/}
            {/*                onClick={handleClick} style={{fontSize: '10pt', color: "black"}}>*/}
            {/*                <PersonIcon style={{*/}
            {/*                    fontSize: '13pt',*/}
            {/*                    color: "black"*/}
            {/*                }}/> {JSON.parse(localStorage.getItem("token")) != null ? JSON.parse(localStorage.getItem("token")).user : t('account')}*/}
            {/*            </Button>*/}
            {/*            <Menu style={{fontSize: '10pt', color: "black"}}*/}
            {/*                  id="demo-positioned-menu"*/}
            {/*                  aria-labelledby="demo-positioned-button"*/}
            {/*                  anchorEl={anchorEl}*/}
            {/*                  open={open}*/}
            {/*                  style={{marginTop: '30px'}}*/}
            {/*                  onClose={handleClose}*/}
            {/*                  anchorOrigin={{*/}
            {/*                      vertical: 'top',*/}
            {/*                      horizontal: 'left',*/}
            {/*                  }}*/}
            {/*                  transformOrigin={{*/}
            {/*                      vertical: 'top',*/}
            {/*                      horizontal: 'left',*/}
            {/*                  }}*/}
            {/*            >*/}
            {/*                {localStorage.getItem("token") == null &&*/}

            {/*                <MenuItem onClick={() => {*/}
            {/*                    closeAll();*/}
            {/*                    setShowLoginModel(false);*/}
            {/*                    setShowRegisterModel(true);*/}
            {/*                }}> <AccountCircle style={{*/}
            {/*                    fontSize: '13pt',*/}
            {/*                    color: "black"*/}
            {/*                }}/> &nbsp;&nbsp;&nbsp;*/}
            {/*                    /!*Register*!/*/}
            {/*                    {t('register')}*/}
            {/*                    &nbsp;&nbsp;&nbsp;</MenuItem>*/}
            {/*                }*/}
            {/*                {localStorage.getItem("token") == null &&*/}
            {/*                <MenuItem onClick={() => {*/}
            {/*                    closeAll();*/}
            {/*                    setShowLoginModel(true);*/}
            {/*                    setShowRegisterModel(false);*/}
            {/*                }*/}
            {/*                }> <LoginIcon style={{*/}
            {/*                    fontSize: '13pt',*/}
            {/*                    color: "black"*/}
            {/*                }}/> &nbsp;&nbsp;&nbsp;*/}
            {/*                    /!*Login*!/*/}
            {/*                    {t('login')}*/}
            {/*                    &nbsp;&nbsp;&nbsp;*/}
            {/*                </MenuItem>*/}
            {/*                }*/}
            {/*                {localStorage.getItem("token") != null &&*/}

            {/*                <MenuItem onClick={() => {*/}
            {/*                    closeAll();*/}
            {/*                    localStorage.removeItem("token");*/}
            {/*                }}> <Logout style={{*/}
            {/*                    fontSize: '13pt',*/}
            {/*                    color: "black"*/}
            {/*                }}/> &nbsp;&nbsp;&nbsp;*/}
            {/*                    /!*logout  *!/*/}
            {/*                    {t('logout')}*/}
            {/*                    &nbsp;&nbsp;&nbsp;</MenuItem>*/}
            {/*                }*/}
            {/*            </Menu>*/}
            {/*        </Col>*/}
            {/*    </Col>*/}
            {/*</Row>*/}
            <Navbar collapseOnSelect expand="lg"   style={{borderBottom: "1px solid black"}}>
                <Container style={{minWidth:'100vw',paddingLeft:'50px'}}>
                    <Navbar.Brand href="/" >
                        <img src={props.data.logo} height='50' width='auto'/>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav"/>

                    <Navbar.Collapse  id="responsive-navbar-nav">
                        <Nav className="">
                            {/*<Nav.Link href="#features">Features</Nav.Link>*/}
                            {/*<Nav.Link className="text-info d-none d-md-block d-sm-block" href="#pricing">Pricing</Nav.Link>*/}
                            {props.routes.map((prop, key) => {
                                if (prop.hide != "true")
                                    return (
                                        <div key={key} className="page "
                                             style={{textAlign: 'center', whiteSpace: 'nowrap'}}
                                        >
                                            <NavLink key={key} title={prop.title}
                                                     style={{
                                                         textAlign: 'center',
                                                         whiteSpace: 'nowrap',
                                                         zIndex: "10002",
                                                         color: 'white'
                                                     }}
                                                     to={prop.layout + prop.path}
                                                     className="nav-link"
                                                     activeClassName="actives">
                                                <h6>
                                                    {t(prop.name)}
                                                    </h6>
                                            </NavLink>
                                        </div>
                                    );
                                else
                                    return (
                                        <NavLink key={key} to={prop.layout + prop.path}>
                                        </NavLink>
                                    );
                            })}
                        </Nav>
                        <Nav id="rightM" style={{marginLeft:'auto'}}>

                            <Row style={{width:"100%"}} >
                                {/*<Col  style={{paddingLeft:"50px",paddingRight:"50px"}}>*/}
                                {/*    <img src={props.data.logo} height='50' width='auto'/>*/}
                                {/*</Col>*/}
                                <Col >
                                    <Col id="headerrtl" style={{display: 'flex',justifyContent:'right'}}>
                                        <div id="searchblock" style={{display: 'flex',marginTop:'5px',backgroundColor:'white'}} title={t('searchproduct')}>
                                            <input placeholder={t('searchproduct')} type="text" id="search"  title={t('searchproduct')}
                                                   style={{
                                                       border: 'none',
                                                       // display: 'none',
                                                       minWidth: "100px",
                                                       padding: "5px",
                                                       marginTop: "5px",
                                                       width: "20vw", borderRadius: '10px',
                                                       maxHeight: "30px!important"
                                                       ,
                                                       fontSize: "10pt"
                                                   }}
                                                   onKeyUp={(e) => {
                                                       if (e.code == "Enter") {
                                                           search();
                                                       }
                                                   }}/>&nbsp;
                                            <span role="button" id="searchButton" ><SearchIcon
                                                style={{fontSize: "20pt", marginTop: "10px"}} title="Search Product"/></span>
                                            {/*<span role="button" style={{ marginTop: "10px"}} id="closeButton"*/}
                                            {/*      onClick={() => hideSearch()}><CloseIcon*/}
                                            {/*    style={{fontSize: "20pt", marginTop: "10px"}}/></span>&nbsp;*/}
                                        </div>

                                        {/**/}
                                        <div>
                                            <Button
                                                style={{fontSize: '13pt',paddingTop:'10px', color: "white"}}
                                                variant="text"
                                                title="Language"
                                                id="demo-positioned-buttona"
                                                aria-controls={openLanguage ? 'demo-positioned-menus' : undefined}
                                                aria-haspopup="true"
                                                aria-expanded={openLanguage ? 'true' : undefined}
                                                onClick={handleClickLanguage}>
                                                <i className="nc-icon nc-world-2" style={{color: "white"}}> </i> &nbsp;
                                                <i className=" fa fa-caret-down"  style={{color: "white"}}> </i> &nbsp;
                                            </Button>
                                            <Menu
                                                id="demo-positioned-menus"
                                                aria-labelledby="demo-positioned-buttona"
                                                anchorEl={anchorElLanguage}
                                                open={openLanguage}
                                                style={{marginTop: '30px'}}
                                                onClose={handleCloseLanguage}
                                                anchorOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'left',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'left',
                                                }}
                                            >
                                                <MenuItem style={{fontSize: '10pt', color: "black"}} onClick={()=>{selectLanguage('fa')}} >
                                                    فارسی
                                                </MenuItem>
                                                <MenuItem style={{fontSize: '10pt', color: "black"}}  onClick={()=>{selectLanguage('pa')}} >
                                                    پشتو
                                                </MenuItem>
                                                <MenuItem style={{fontSize: '10pt', color: "black"}}  onClick={()=>{selectLanguage('en')}}>
                                                    English
                                                </MenuItem>
                                            </Menu>
                                        </div>

                                        <Button
                                            style={{fontSize: '10pt', color: "white"}}
                                            variant="text"
                                            title="Cart"
                                            id="demo-positioned-buttona"
                                            aria-controls={opena ? 'demo-positioned-menus' : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={opena ? 'true' : undefined}
                                            onClick={handleClicka}>
                                            <ShoppingCart style={{fontSize: '13pt', color: "white"}}/>
                                            &nbsp;
                                            <i className=" fa fa-cart" style={{color: "white"}}> </i>
                                            {t('cart')}
                                            {/*<i className=" fa fa-caret-down"> </i>*/}
                                            {/*Cart*/}
                                        </Button>
                                        <Menu
                                            id="demo-positioned-menus"
                                            aria-labelledby="demo-positioned-buttona"
                                            anchorEl={anchorEla}
                                            open={opena}
                                            style={{marginTop: '30px'}}
                                            onClose={handleClosea}
                                            anchorOrigin={{
                                                vertical: 'top',
                                                horizontal: 'left',
                                            }}
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'left',
                                            }}
                                        >
                                            <MenuItem style={{fontSize: '10pt', color: "black"}} onClick={() => {
                                                closeAll();
                                                setShowLoginModel(false);
                                                setShowRegisterModel(false);
                                                getCart();
                                            }}>
                                                <FavoriteIcon
                                                    style={{fontSize: '10pt', color: "white"}}/> &nbsp; {t('cart')}
                                                {/*Cart */}
                                                &nbsp;&nbsp;
                                                <div style={{
                                                    display: 'flex',
                                                    backgroundColor: '#1da9e1',
                                                    color: '#fff',
                                                    borderRadius: '10px 10px 10px 10px'
                                                }}> &nbsp; {cartCount} &nbsp;</div>
                                            </MenuItem>
                                        </Menu>
                                        <Button
                                            variant="text"
                                            title={t('account')}
                                            id="demo-positioned-button"
                                            aria-controls={open ? 'demo-positioned-menu' : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={open ? 'true' : undefined}
                                            onClick={handleClick} style={{fontSize: '10pt', color: "white"}}>
                                            &nbsp; <PersonIcon style={{fontSize: '13pt', color: "white"}}/>&nbsp;
                                            {JSON.parse(localStorage.getItem("token")) != null ? JSON.parse(localStorage.getItem("token")).user : t('account')}
                                            &nbsp;
                                        </Button>
                                        <Menu style={{fontSize: '10pt', color: "black"}}
                                              id="demo-positioned-menu"
                                              aria-labelledby="demo-positioned-button"
                                              anchorEl={anchorEl}
                                              open={open}
                                              style={{marginTop: '30px'}}
                                              onClose={handleClose}
                                              anchorOrigin={{
                                                  vertical: 'top',
                                                  horizontal: 'left',
                                              }}
                                              transformOrigin={{
                                                  vertical: 'top',
                                                  horizontal: 'left',
                                              }}
                                        >
                                            {localStorage.getItem("token") == null &&

                                            <MenuItem onClick={() => {
                                                closeAll();
                                                setShowLoginModel(false);
                                                setShowRegisterModel(true);
                                            }}> <AccountCircle style={{
                                                fontSize: '13pt',
                                                color: "black"
                                            }}/> &nbsp;&nbsp;&nbsp;
                                                {/*Register*/}
                                                {t('register')}
                                                &nbsp;&nbsp;&nbsp;</MenuItem>
                                            }
                                            {localStorage.getItem("token") == null &&
                                            <MenuItem onClick={() => {
                                                closeAll();
                                                setShowLoginModel(true);
                                                setShowRegisterModel(false);
                                            }
                                            }> <LoginIcon style={{
                                                fontSize: '13pt',
                                                color: "black"
                                            }}/> &nbsp;&nbsp;&nbsp;
                                                {/*Login*/}
                                                {t('login')}
                                                &nbsp;&nbsp;&nbsp;
                                            </MenuItem>
                                            }
                                            {localStorage.getItem("token") != null &&

                                            <MenuItem onClick={() => {
                                                closeAll();
                                                localStorage.removeItem("token");
                                            }}> <Logout style={{
                                                fontSize: '13pt',
                                                color: "black"
                                            }}/> &nbsp;&nbsp;&nbsp;
                                                {/*logout  */}
                                                {t('logout')}
                                                &nbsp;&nbsp;&nbsp;</MenuItem>
                                            }
                                        </Menu>
                                    </Col>
                                </Col>
                            </Row>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <a href={whatsapp} target="_blank" style={{
                bottom: "0px",
                right: "0px",
                position: "fixed",
                padding: "10px",
                margin: "30px",
                color: "white",
                borderRadius: "50%",
                backgroundColor: "#08d251",
                zIndex: "1000"
            }}>
                <div>
                    <WhatsAppIcon fontSize="large"/>
                </div>
            </a>
        </>
    );
}

export default Head;
