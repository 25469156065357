import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Skeleton from "@mui/material/Skeleton";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import SearchIcon from "@mui/icons-material/Search";
import React from "react";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";

function SubCategory(pro) {
    const showEffect = async (c) => {
        document.getElementById(c).style.display = 'block';
        document.getElementById(c + "s").style.display = 'block';
    }
    const hideEffect = async (c) => {
        document.getElementById(c).style.display = 'none';
        document.getElementById(c + "s").style.display = 'none';
    }
    const {t, i18n} = useTranslation();
    const history = useHistory();
    return (
        <>
            {
                pro.subCategoryStatus == false &&
                <Row className="" style={{display: 'flex', marginRight: '0', justifyContent: 'center'}}>
                    <Col md={{span: 10}}>
                        {[1, 2, 3, 4, 5, 6].map((e, index) => (
                            <div key={index}>
                                <Row style={{display: 'flex', textAlign: 'center'}}>
                                    <Col style={{marginLeft: '44%'}}>
                                        <Skeleton variant="rectangular" color='red' animation='pulse' width={200}
                                                  height={40}/>
                                    </Col>

                                </Row>
                                <Row style={{display: 'flex', marginRight: '0', justifyContent: 'center'}}>
                                    {
                                        [1, 2, 3, 4, 5, 6].map((p, indexs) => (
                                                <Col key={indexs} className="col-md-2 p-xxl-2">
                                                    <Row className="p-xxl-2">
                                                        <Col>
                                                            <Skeleton variant="rectangular"
                                                                      color='red' animation='pulse'
                                                                      height={200}/>
                                                        </Col>
                                                    </Row>
                                                    <Row className="p-xxl-2">
                                                        <Col>
                                                            <Skeleton variant="rectangular"
                                                                      color='red' animation='pulse'
                                                                      height={40}/>
                                                        </Col>
                                                    </Row>
                                                    <Row className="p-xxl-2">
                                                        <Col>
                                                            <Skeleton variant="rectangular"
                                                                      color='red' animation='pulse'
                                                                      height={20} width={100}/>
                                                        </Col>
                                                    </Row>
                                                    <Row className="p-xxl-2">
                                                        <Col>
                                                            <Skeleton variant="rectangular"
                                                                      color='red' animation='pulse'
                                                                      height={30}/>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            )
                                        )
                                    }
                                </Row>
                                <Row style={{display: 'flex', textAlign: 'center', height: '100px'}}>
                                    <Col style={{marginLeft: '44%'}}>
                                        <Skeleton variant="rectangular" color='red' animation='pulse' width={200}
                                                  height={60}/>
                                    </Col>
                                </Row>
                                <br/>
                            </div>
                        ))}
                    </Col>
                </Row>
            }
            {
                pro.subCategoryStatus == true &&
                <div>
                <Row id="headerrtl" className="d-none d-lg-flex d-md-flex"  style={{display: 'flex', marginRight: '0'}}>
                    <Col md={{span: 10, offset: 1}}>
                        {pro.subCategoryList.map((e, index) => (
                            <div key={index}>
                                <hr style={{maxWidth: '500px', textAlign: 'center', margin: '0 auto'}}/>
                                <br/>
                                <Row style={{display: 'flex', textAlign: 'center'}}>
                                    <Col style={{color: 'red'}}>
                                            <span
                                                className="h3 text-uppercase "
                                                style={{color: '#0065b3'}}>{e.sub_category}</span><br/>
                                        <span className="" style={{
                                            maxWidth: ' 70px',
                                            borderTop: '4px solid #0065b3',
                                            display: ' block',
                                            margin: '0 auto'
                                        }}>&nbsp;</span>
                                    </Col>
                                </Row>
                                <Row style={{display: 'flex',justifyContent:"center"}}>
                                    {
                                        e.product.map((p, index) => (
                                                <Col key={index} className=" p-xxl-2" style={{
                                                    height: "auto",
                                                    maxWidth: "300px"
                                                }}  >
                                                    <Row className="p-xl-1" >
                                                        <Col >
                                                            {p.image != null &&
                                                            <div role='button' style={{position: "relative", height: "250px",border: "1px solid gray",textAlign:'center'}}
                                                                 onMouseOver={() => showEffect("id" + p.id)}
                                                                 onClick={() => {
                                                                     pro.setData(p);
                                                                     pro.setShowModel(true);
                                                                 }}
                                                                 onMouseOut={() => hideEffect("id" + p.id)}>
                                                                <img src={p.image} height='auto' width='auto'
                                                                     style={{position: 'relative',maxHeight:"250px"}}/>
                                                                <div
                                                                    onClick={() => {
                                                                        pro.item.setSubCategoryId(e.id);
                                                                        pro.item.setSubCategoryPage(true);
                                                                    }}
                                                                    style={{
                                                                        height: "auto",
                                                                        maxHeight:"250px",
                                                                        width: "auto",
                                                                        position: "absolute",
                                                                        display: 'block',
                                                                        top: '0',
                                                                        padding: '5px',
                                                                    }} className=""></div>

                                                                <div id={"id" + p.id + "s"} style={{
                                                                    width: "100%",
                                                                    textAlign: 'center',
                                                                    opacity: '0.9',
                                                                    position: "absolute",
                                                                    bottom: '0',
                                                                    padding: '5px',
                                                                    fontSize: '14pt',
                                                                    whiteSpace: "nowrap",
                                                                    display: 'none',
                                                                    backgroundColor: '#4d8ffa91'
                                                                }} className="text-light ">
                                                                    {/*Add To Cart*/}
                                                                    {t('addtocart')}
                                                                </div>
                                                                <div id={"id" + p.id} style={{
                                                                    width: "50px",
                                                                    display: 'none',
                                                                    position: "absolute",
                                                                    right: '10px',
                                                                    bottom: '50px',
                                                                    fontSize: '20px',
                                                                    index: '1000'
                                                                }} className=" text-light">
                                                                    {/*<FavoriteBorderIcon*/}
                                                                    {/*    onClick={() => {*/}
                                                                    {/*        alert("Please register")*/}
                                                                    {/*    }}*/}
                                                                    {/*    style={{*/}
                                                                    {/*        backgroundColor: 'rgba(255, 255, 255, 1)',*/}
                                                                    {/*        borderRadius: '45%'*/}
                                                                    {/*    }} color='success' fontSize='medium'/>*/}
                                                                    {/*<br/><br/>*/}
                                                                    <SearchIcon

                                                                        style={{
                                                                            backgroundColor: 'rgba(255, 255, 255, 1)',
                                                                            borderRadius: '45%'
                                                                        }} color='success' fontSize='medium'/><br/>
                                                                </div>

                                                            </div>
                                                            }
                                                            {p.image == null &&
                                                            <Skeleton variant="rectangular" color='red'
                                                                      animation='pulse' height={200}/>
                                                            }
                                                        </Col>

                                                    </Row>
                                                    <Row className="p-xl-1">
                                                        <Col>
                                                            {p.name != null &&
                                                            <span
                                                                className="text-info  h6 text-capitalize">{p.name}</span>
                                                            }
                                                            {p.name == null &&
                                                            <Skeleton variant="rectangular" color='red'
                                                                      animation='pulse' height={40}/>
                                                            }
                                                        </Col>
                                                    </Row>
                                                    <Row className="p-xl-1">
                                                        <Col>
                                                            {p.unit_name != null &&
                                                            <span
                                                                className="text-info  h6 text-capitalize">1 {p.unit_name}</span>
                                                            }
                                                            {p.unit_name == null &&
                                                            <Skeleton variant="rectangular" color='red'
                                                                      animation='pulse' height={30}/>
                                                            }
                                                        </Col>
                                                    </Row>
                                                    <Row className="p-xl-1">
                                                        <Col>
                                                            {p.shipping_info != null &&
                                                            <span
                                                                className="text-info text-capitalize">1 {p.shipping_info}</span>
                                                            }
                                                            {p.shipping_info == null &&
                                                            <Skeleton variant="rectangular" color='red'
                                                                      animation='pulse' height={30}/>
                                                            }
                                                        </Col>
                                                    </Row>
                                                    <Row className="p-xl-1">
                                                        <Col>
                                                            {p.amount != null &&
                                                            <div>
                                                                <span className="text-default  text-decoration-line-through fst-italic"> {p.p_amount}</span>
                                                                <span className="text-default h6 text-capitalize"> {p.amount}</span>
                                                                <span className="text-default h6 text-capitalize"> {p.cur_name}</span>
                                                            </div>
                                                            }
                                                            {p.amount == null &&
                                                            <Skeleton variant="rectangular" color='red'
                                                                      animation='pulse' height={30}/>
                                                            }
                                                        </Col>
                                                    </Row>

                                                </Col>
                                            )
                                        )
                                    }
                                </Row>
                                <Row style={{display: 'flex', textAlign: 'center', height: '100px'}}>
                                    <Col>
                                            <span className="text-uppercase text-decoration-underline">
                                                <span className="" role="button" onClick={() => {
                                                    localStorage.setItem("subCategoryId", e.product[0].sub_category_id);
                                                    history.push('/all_product');

                                                }}>  {t('viewmore')}</span>
                                            </span>
                                    </Col>
                                </Row>
                            </div>
                        ))}
                    </Col>
                </Row>
                </div>
            }
        </>
    );
}

export default SubCategory;
