import React, {useEffect} from "react";
import Axios from "axios";
import {headers, mainUrl} from "../../../../common/common";
import UsProductBody from "./UsProductBody";

function UsProduct() {
    const [searchProduct, setSearchProduct] = React.useState([]);
    const [searchProductStatus, setSearchProductStatus] = React.useState(false);

    useEffect(() => {
        LoadMenu();
    },[localStorage.getItem("search")]);

    function search() {
        return Axios.post(mainUrl + "usProduct?page=1",
            {
                'us': "true",
                'search': "",
                'lang':localStorage.getItem("lang"),
                'sub_category_id': ""
            } ,{headers});
    }

    const LoadMenu = async () => {
        await Promise.all([search()])
            .then(function (results) {
                setSearchProduct(results[0].data);
                setSearchProductStatus(true);
            }).catch(function (thrown) {
                if (Axios.isCancel(thrown)) {
                    console.log('Request canceled', thrown.message);
                } else {
                    // handle error
                }
            });
        ;
    }

    return (
        <>
            <UsProductBody
                searchProduct={searchProduct}
                searchProductStatus={searchProductStatus}
                setSearchProduct={setSearchProduct}
            />
        </>
    );
}

export default UsProduct;
