import React, {useEffect, useState} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {Input} from "reactstrap";

import {Button} from "react-bootstrap";
import Axios from "axios";
import {headers, mainUrl, webUrl} from "../../../../../common/common";
import toast from "react-hot-toast";
import PhoneInput from "react-phone-number-input";

import 'react-phone-number-input/style.css'
import en from 'react-phone-number-input/locale/en'
import {useTranslation} from "react-i18next";

function Registers(props) {
    const [updateStatus, setUpdateStatus] = useState('f');
    const [error, setError] = useState(false);
    const [lenError, setLenError] = useState(false);
    const [value, setValue] = useState();
    const {t, i18n} = useTranslation();
    const [passwordStatus, setPasswordStatus] = useState(false);
    const [conPasswordStatus, setConPasswordStatus] = useState(false);

    useEffect(() => {
        if (localStorage.getItem("token") != null) {
            setUpdateStatus('t');
            Axios.post(mainUrl + "getCustomerByToken", {'token': JSON.parse(localStorage.getItem("token"))},
                {headers}).then(response => {
                document.getElementById("id").value = response.data.id;
                document.getElementById("name").value = response.data.name;
                document.getElementById("email").value = response.data.email;

                setValue(response.data.phone);
            });
        }
    }, []);

    const submitForm = async (e) => {
        e.preventDefault();
        const f = e.target;
        if (f.password.value.length < 8) {
            document.getElementById("submit").disabled = false;
            setLenError(true);
        } else if (f.confirm_password.value != f.password.value) {
            setError(true);
            document.getElementById("submit").disabled = false;
        } else {
            setError(false);
            if (localStorage.getItem("token") != null) {
                await Axios.post(mainUrl + "updateCustomer", {
                    id: f.id.value,
                    name: f.name.value,
                    country: en[f.phoneCountry.value],
                    last_name: "",
                    email: f.email.value,
                    phone: f.phone.value,
                    token: JSON.parse(localStorage.getItem("token")),
                    address: "",
                }, {headers}).then(res => {
                    localStorage.setItem("token", JSON.stringify(res.data));
                    notifySuccess("Successfully Updated ");
                    props.setShowRegisterModel(false);
                }).catch(function (error) {
                    notifyError(error.response.data.error);
                });

            } else {
                await Axios.post(mainUrl + "registerCustomer", {
                    id: f.id.value,
                    name: f.name.value,
                    country: en[f.phoneCountry.value],
                    last_name: "",
                    email: f.email.value,
                    phone: f.phone.value,
                    password: f.password.value,
                    confirm_password: f.confirm_password.value,
                    address: "",
                }, {headers}).then(res => {
                    notifySuccess("Successfully Register");
                    notifySuccess("Please Login!.");
                    props.setShowRegisterModel(false);
                }).catch(function (error) {
                    notifyError(error.response.data.error);
                });
            }
            document.getElementById("submit").disabled = false;
        }
    };
    const notifyError = (message) => toast.error(message, {
        duration: 6000,
    });
    const notifySuccess = (message) => toast.success(message, {
        duration: 6000,
    });

    const hideshowpassword = () => {
        var x = document.getElementById("password");
        if (x.type === "password") {
            x.type = "text";
            setPasswordStatus(false);
        } else {
            x.type = "password";
            setPasswordStatus(true);
        }
    }
    const hideshowconPassword = () => {
        var x = document.getElementById("confirm_password");
        if (x.type === "password") {
            x.type = "text";
            setConPasswordStatus(false);
        } else {
            x.type = "password";
            setConPasswordStatus(true);
        }
    }

    const forgotUrl = webUrl + "userChangePassword";
    return (
        <>
            <form onSubmit={(e) => {
                document.getElementById("submit").disabled = true;
                submitForm(e)
            }}>
                <Row>
                    <Col className="col-md-12 pr-3 pl-3">
                        <Row>
                            <Input name="id" id="id" type="hidden"/>
                            <Col md="12">
                                <div className="form-group">
                                    <label>
                                        {/*Name*/}
                                        <input type="hidden" id="id" name="id"/>
                                        {t('username')}
                                        <span className="text text-danger">*</span></label>
                                    <Input required className="w-100" name="name" id="name" required
                                           placeholder={t('name')}
                                           title={t('name')} type="text"/>
                                </div>
                            </Col>

                            {/*<Col md="12">*/}
                            {/*    <div className="form-group">*/}
                            {/*        <label>*/}
                            {/*            {t('lastname')}*/}
                            {/*            /!*Last Name*!/*/}
                            {/*        </label>*/}
                            {/*        <Input  className="w-100"  name="last_name" id="last _name"*/}
                            {/*               placeholder={t('lastname')} title={t('lastname')}*/}
                            {/*               type="text"/>*/}
                            {/*    </div>*/}
                            {/*</Col>*/}
                            <Col md="12">
                                <div className="form-group">
                                    <label>
                                        {/*Email*/}
                                        {t('email')}
                                        <span className="text text-danger">*</span></label>
                                    <Input className="w-100" required name="email" id="email"
                                           placeholder={t('emailplace')} title={t('email')}
                                           type="email"/>
                                </div>
                            </Col>
                            <Col md="12">
                                <div className="form-group">
                                    <label>
                                        {/*Phone Number*/}
                                        {t('phone')}
                                        <span className="text text-danger">*</span></label>
                                    <PhoneInput
                                        placeholder={t('phoneplace')}
                                        value={value}
                                        name="phone"
                                        id="phone"
                                        defaultCountry="AF"
                                        onChange={setValue}/>
                                </div>
                            </Col>

                            {/*<Col  md="12">*/}
                            {/*    <div className="form-group">*/}
                            {/*        <label>*/}
                            {/*            /!*Address*!/*/}
                            {/*            {t('address')}*/}
                            {/*            <span className="text text-danger">*</span></label>*/}
                            {/*        <Input className="w-100" type="textarea"  required name="address" id="address"*/}
                            {/*               placeholder={t('address')} title={t('address')}/>*/}
                            {/*    </div>*/}
                            {/*</Col>*/}

                        </Row>
                        {(updateStatus ==='f') &&
                            <Row>
                            <Col md="12">
                                <div className="form-group">
                                    <label>
                                        {/*Password*/}
                                        {t('password')}
                                        <span className="text text-danger">*</span></label>
                                    <div className="w-100 bg-light"
                                         style={{display: 'flex', borderRadius: '5px'}}>
                                        <div style={{width: '100%'}}>
                                            <input onKeyUp={() => {
                                                setLenError(false);
                                            }} required style={{border: '0px solid'}} name="password" id="password"
                                                   type="password"
                                                   className="w-100 form-control "
                                                   title={t('password')}
                                                   placeholder={t('password')}
                                            />
                                        </div>
                                        <div style={{padding: '10px'}} onClick={(e) => hideshowpassword()}
                                             role="button">
                                            {passwordStatus == true &&
                                            <i className="fa fa-eye"/>
                                            }
                                            {passwordStatus == false &&
                                            <i className="fa fa-eye-slash"/>
                                            }
                                        </div>
                                    </div>

                                    {/*<Input className="w-100" required name="password" id="password"*/}
                                    {/*       placeholder={t('password')} title={t('password')}*/}
                                    {/*       type="password"/>*/}

                                    {t('passwodpolicy')}

                                </div>
                            </Col>
                            <Col md="12">
                                <div className="form-group">
                                    <label>
                                        {/*Confirm Password*/}
                                        {t('confitmpassword')}
                                        <span className="text text-danger">*</span></label>
                                    {/*<Input className="w-100" required onKeyUp={()=>{setError(false);}}  name="confirm_password" id="confirm_password"*/}
                                    {/*       placeholder={t('confitmpassword')} title={t('confitmpassword')}*/}
                                    {/*       type="password"/>*/}
                                    <div className="w-100 bg-light"
                                         style={{display: 'flex', borderRadius: '5px'}}>
                                        <div style={{width: '100%'}}>
                                            <input required style={{border: '0px solid'}} onKeyUp={() => {
                                                setError(false);
                                            }} name="confirm_password" id="confirm_password"
                                                   type="password"
                                                   className="w-100 form-control "
                                                   title={t('confitmpassword')}
                                                   placeholder={t('confitmpassword')}
                                            />
                                        </div>
                                        <div style={{padding: '10px'}} onClick={(e) => hideshowconPassword()}
                                             role="button">
                                            {conPasswordStatus == true &&
                                            <i className="fa fa-eye"/>
                                            }
                                            {conPasswordStatus == false &&
                                            <i className="fa fa-eye-slash"/>
                                            }
                                        </div>
                                    </div>

                                    {
                                        error == true &&
                                        <div className="text-danger">
                                            {t('confirmmessage')}
                                            {/*The password confirmation does not match.*/}
                                        </div>
                                    }
                                    {
                                        lenError == true &&
                                        <div className="text-danger">
                                            {t('lenpassowd')}
                                            {/*The password confirmation does not match.*/}
                                        </div>
                                    }
                                </div>
                            </Col>
                        </Row>
                        }
                    </Col>
                </Row>
                <Row>
                    <Col className="text-center">
                        {/*#454444*/}
                        <Button style={{backgroundColor: 'rgb(29 169 225)', width: '100%'}} id="submit" type="submit">
                            {/*Register*/}
                            {t('register')}
                        </Button>
                        {(updateStatus=='t') &&
                        <a className="badge badge-danger " title="forgot Password" style={{ margin:'20px'}} target="_blank"
                           href={forgotUrl}>
                            {/*Forgot Password*/}
                            {t('fpassword')}
                        </a>
                        }
                        {(updateStatus=='f') &&
                        <div>
                        <span>
                            {t('registerdetail')}
                        </span>
                        <hr/>
                        {t('haveaccount')}
                        <div role="button"
                             onClick={() => {
                                 props.setShowLoginModel(true);
                                 props.setShowRegisterModel(false);
                             }}
                             className="badge badge-default">{t('login')}</div>
                        </div>}

                    </Col>
                </Row>
            </form>
        </>
    );
}

export default Registers;
