import React, {useEffect, useState} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Box from "@mui/material/Box";
import {mainUrl, toBase64} from "../../../../common/common";
import Axios from "axios";
import toast from "react-hot-toast";
import {padding} from "@mui/system";

import {Button} from "react-bootstrap";
import {useTranslation} from "react-i18next";
function WashLists(props) {

    const [quantity, setQuantity] = useState(0);

    const notifyError = (message) => toast.error(message, {
        duration: 6000,
    });
    const notifySuccess = (message) => toast.success(message, {
        duration: 6000,
    });
    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Header': '*',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
    }

     const updateCart = async (id, quantity) => {
    console.log(t('success'));
        if (localStorage.getItem("token") != null) {
            await Axios.post(mainUrl + "updateCart", {
                quantity: quantity,
                detail_id: id,
                token: JSON.parse(localStorage.getItem("token")),
            }, {headers}).then(res => {
                notifySuccess(t('success'));

            }).catch(function (error) {
                notifyError(error.response.data.message);
            });
        } else {
            alert(t('pllogin'));
        }
    }
     const deleteCart = async(id) => {
        if (localStorage.getItem("token") != null) {
            await Axios.post(mainUrl + "deleteCart", {
                quantity: quantity,
                detail_id: id,
                token: JSON.parse(localStorage.getItem("token")),
            }, {headers}).then(res => {
                if (localStorage.getItem('cart') != null) {
                    localStorage.setItem('cart', Number(localStorage.getItem('cart')) - 1);
                } else {
                    localStorage.setItem('cart', 0);
                }
                notifySuccess(t('success'));
                let newData=props.data.filter(e=>{if(e.id!=id){return e;}});
                props.setData(newData);
            }).catch(function (error) {
                notifyError(error.response.data.message);
            });
        } else {
            alert(t('pllogin'));
        }
    }

    const submitCart= async () => {
        if (localStorage.getItem("token") != null) {
            await Axios.post(mainUrl + "submitCart", {
                token: JSON.parse(localStorage.getItem("token")),
            }, {headers}).then(res => {
                    localStorage.setItem('cart', 0);
                notifySuccess(t('success'));
                // notifySuccess('Submit Successfully.');
            }).catch(function (error) {
                notifyError(error.response.data.message);
            });
        } else {
            alert(t('pllogin'));
            // alert('Please Login');
        }
    }
    const {t, i18n} = useTranslation();
    return (
        <Row style={{maxHeight: '600px', overflowY: 'auto', overflowX: 'hidden'}} className="col-md-12 ">
            <table className="table ">
                <thead>
                <tr style={{textAlign: 'center'}}>
                    <th>{t('no')}</th>
                    <th>{t('product')}</th>
                    <th>{t('unittype')}</th>
                    <th>{t('quantity')}</th>
                    <th>{t('total')}</th>
                </tr>
                </thead>
                <tbody>
                {
                    props.data.map((p, index) => (
                        <tr key={index} style={{textAlign: 'center'}}>

                            <td >{index + 1}</td>
                            <td >{p.product_name}</td>
                            <td >{p.unit_price} {p.currency_name}</td>
                            <td >
                                <Row>
                                    <Col>
                                        <Row>
                                            <Col style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                paddingBottom: '5px'
                                            }}>
                                                <div style={{border: '1px solid gray'}}>
                                                    <KeyboardArrowLeftIcon fontSize='large' role='button'
                                                                           onClick={() => {
                                                                               if (p.quantity != 0) {
                                                                                   let newData=props.data.filter(e=>{if(e.id==p.id){e.quantity=e.quantity-1;e.total=e.quantity*e.unit_price;} return e});
                                                                                   props.setData(newData);
                                                                               }
                                                                           }}/>
                                                </div>
                                                <div
                                                    style={{
                                                        width: '100px',
                                                        textAlign: 'center',
                                                        border: '1px solid gray'
                                                    }}>
                                                    {p.quantity}
                                                </div>
                                                <div style={{border: '1px solid gray'}}>
                                                    <KeyboardArrowRightIcon fontSize='large' role='button'
                                                                            onClick={() => {
                                                                                let newData=props.data.filter(e=>{if(e.id==p.id){e.quantity=e.quantity+1;e.total=e.quantity*e.unit_price;} return e});
                                                                                props.setData(newData);
                                                                            }}/>
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col style={{display: 'flex', justifyContent: 'center'}}>
                                                <div role='button'
                                                     className='btns border-1 border text-info' onClick={() => updateCart(p.id,p.quantity)}>
                                                    {/*Update*/}
                                                    {t('update')}
                                                </div>
                                                &nbsp;
                                                <div role='button'
                                                     className='btns  border-1 border text-info' onClick={() => deleteCart(p.id)}>
                                                    {/*Delete*/}
                                                    {t('delete')}
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </td>
                            <td>{p.total} {p.currency_name}</td>
                        </tr>
                    ))
                }
                </tbody>
            </table>
            <div style={{float:'right',textAlign:'right'}}>
            <Button role="button" style={{backgroundColor:'#1da9e1',width:'100%'}} onClick={()=>submitCart()}>Submit</Button>
            </div>
        </Row>
    );
}

export default WashLists;
