import {Col, Container, Row} from "react-bootstrap";
import PhoneIcon from '@mui/icons-material/Phone';
import FavoriteIcon from '@mui/icons-material/Favorite';
import PersonIcon from '@mui/icons-material/Person';
import LoginIcon from '@mui/icons-material/Login';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Logout from '@mui/icons-material/Logout';

import SearchIcon from '@mui/icons-material/Search';
import React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import orderLogo from "../../../assets/img/logo.png";
import Input from "@mui/material/Input";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import DemoNavbar from "../../../components/Navbars/DemoNavbar";
import routes from "../../../routes";
import LoginModel from "../body/customer/login/LoginModel";
import toast, {Toaster} from "react-hot-toast";
import RegisterModel from "../body/customer/register/RegisterModel";
import Button from "@mui/material/Button";
import WashListModel from "../body/washlist/WashListModel";
import Axios from "axios";
import {mainUrl} from "../../../common/common";
import {ManageSearch, ShoppingCart} from "@mui/icons-material";

import {useHistory} from "react-router";
import Head from "./Head";
import Headv3 from "./Headv3";

function Headers(props) {

    //Start Progress
    const [cartCount, setCartCount] = React.useState(0);
    const [progress, setProgress] = React.useState(0);
    const [progressStatus, setProgressStatus] = React.useState(true);
    const [buffer, setBuffer] = React.useState(10);
    const [showRegisterModel, setShowRegisterModel] = React.useState(false);
    const [showLoginModel, setShowLoginModel] = React.useState(false);
    const [showWashListModel, setShowWashListModel] = React.useState(false);
    const [data, setData] = React.useState([]);

    const progressRef = React.useRef(() => {
    });

    const navigate = useHistory();

    const search = async () => {
        const value=document.getElementById("search").value;
        if(value!=""){
            localStorage.setItem('search', value)
            navigate.push("/search");
        }
    }

    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Header': '*',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
    React.useEffect(() => {
        setInterval(() => {
            setCartCount(Number(localStorage.getItem('cart')));
        }, 100);
    }, [])
    React.useEffect(() => {
        progressRef.current = () => {
            if (progress > 100) {
                setProgressStatus(false);
            } else {
                const diff = Math.random() * 10;
                const diff2 = Math.random() * 10;
                setProgress(progress + diff);
                setBuffer(progress + diff + diff2);
            }
        };
    });
    React.useEffect(() => {
        const timer = setInterval(() => {
            progressRef.current();
        }, 100);
        return () => {
            clearInterval(timer);
        };
    }, []);

    //End  Progress

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorEla, setAnchorEla] = React.useState(null);
    const open = Boolean(anchorEl);
    const opena = Boolean(anchorEla);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClicka = (event) => {
        setAnchorEla(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleClosea = () => {
        setAnchorEla(null);
    };
    const notifyError = (message) => toast.error(message, {
        duration: 6000,
    });
    const notifySuccess = (message) => toast.success(message, {
        duration: 6000,
    });
    const whatsapp = "https://api.whatsapp.com/send?phone=" + props.data.whatsapp_link;
    const getCart = async () => {
        if (localStorage.getItem("token") != null) {
            await Axios.post(mainUrl + "getCart", {
                token: JSON.parse(localStorage.getItem("token")),
            }, {headers}).then(res => {
                setData(res.data);
                setShowWashListModel(true);
            }).catch(function (error) {
                notifyError(error.response.data.message);
            });
        } else {
            alert('Please Login');
        }
    };
    return (
        <>
            {/*<Head {...props} routes={routes}/>*/}
            <Headv3 {...props} routes={routes}/>
            {/*<Toaster position="top-right" reverseOrder={false}/>*/}
            {/*<RegisterModel setShowRegisterModel={setShowRegisterModel} showRegisterModel={showRegisterModel}/>*/}
            {/*<WashListModel setShowWashListModel={setShowWashListModel} showWashListModel={showWashListModel} data={data}*/}
            {/*               setData={setData}/>*/}
            {/*<LoginModel setShowLoginModel={setShowLoginModel} showLoginModel={showLoginModel}/>*/}
            {/*/!*Progress*!/*/}
            {/*{progressStatus == true &&*/}
            {/*<Box sx={{width: '100%'}}>*/}
            {/*    <LinearProgress color="success" variant="buffer" value={progress} valueBuffer={buffer}/>*/}
            {/*</Box>*/}
            {/*}*/}

            {/*<Container style={{margin: '0', maxWidth: '100%'}}>*/}
            {/*    <Row style={{width: '100%', position: 'absolute'}}>*/}

            {/*        <Col   style={{right: '0', textAlign: 'right'}}>*/}
            {/*            <Button style={{top:"10px"}}>*/}
            {/*                <div id="google_translate_element" style={{ float: "right"}}></div>*/}
            {/*            </Button>*/}
            {/*            <Button*/}
            {/*                variant="text"*/}
            {/*                id="demo-positioned-buttona"*/}
            {/*                aria-controls={opena ? 'demo-positioned-menus' : undefined}*/}
            {/*                aria-haspopup="true"*/}
            {/*                aria-expanded={opena ? 'true' : undefined}*/}
            {/*                onClick={handleClicka}>*/}
            {/*                <ShoppingCart/> Cart*/}
            {/*            </Button>*/}
            {/*            <Menu*/}
            {/*                id="demo-positioned-menus"*/}
            {/*                aria-labelledby="demo-positioned-buttona"*/}
            {/*                anchorEl={anchorEla}*/}
            {/*                open={opena}*/}
            {/*                style={{marginTop: '30px'}}*/}
            {/*                onClose={handleClosea}*/}
            {/*                anchorOrigin={{*/}
            {/*                    vertical: 'top',*/}
            {/*                    horizontal: 'left',*/}
            {/*                }}*/}
            {/*                transformOrigin={{*/}
            {/*                    vertical: 'top',*/}
            {/*                    horizontal: 'left',*/}
            {/*                }}*/}
            {/*            >*/}
            {/*                <MenuItem onClick={() => {*/}
            {/*                    setShowLoginModel(false);*/}
            {/*                    setShowRegisterModel(false);*/}
            {/*                    getCart();*/}
            {/*                }}>*/}
            {/*                    <FavoriteIcon/> &nbsp; Cart &nbsp;&nbsp;*/}
            {/*                    <div style={{*/}
            {/*                        display: 'flex',*/}
            {/*                        backgroundColor: '#1da9e1',*/}
            {/*                        color: '#fff',*/}
            {/*                        borderRadius: '10px 10px 10px 10px'*/}
            {/*                    }}> &nbsp; {cartCount} &nbsp;</div>*/}
            {/*                </MenuItem>*/}
            {/*            </Menu>*/}
            {/*            <Button*/}
            {/*                variant="text"*/}
            {/*                id="demo-positioned-button"*/}
            {/*                aria-controls={open ? 'demo-positioned-menu' : undefined}*/}
            {/*                aria-haspopup="true"*/}
            {/*                aria-expanded={open ? 'true' : undefined}*/}
            {/*                onClick={handleClick}>*/}
            {/*                <PersonIcon/> {JSON.parse(localStorage.getItem("token")) != null ? JSON.parse(localStorage.getItem("token")).user : "Account"}*/}
            {/*            </Button>*/}
            {/*            <Menu*/}
            {/*                id="demo-positioned-menu"*/}
            {/*                aria-labelledby="demo-positioned-button"*/}
            {/*                anchorEl={anchorEl}*/}
            {/*                open={open}*/}
            {/*                style={{marginTop: '30px'}}*/}
            {/*                onClose={handleClose}*/}
            {/*                anchorOrigin={{*/}
            {/*                    vertical: 'top',*/}
            {/*                    horizontal: 'left',*/}
            {/*                }}*/}
            {/*                transformOrigin={{*/}
            {/*                    vertical: 'top',*/}
            {/*                    horizontal: 'left',*/}
            {/*                }}*/}
            {/*            >*/}
            {/*                {localStorage.getItem("token") == null &&*/}

            {/*                <MenuItem onClick={() => {*/}
            {/*                    setShowLoginModel(false);*/}
            {/*                    setShowRegisterModel(true);*/}
            {/*                }}> <AccountCircle/> &nbsp;&nbsp;&nbsp;  Register  &nbsp;&nbsp;&nbsp;</MenuItem>*/}
            {/*                }*/}
            {/*                {localStorage.getItem("token") == null &&*/}
            {/*                <MenuItem onClick={() => {*/}
            {/*                    setShowLoginModel(true);*/}
            {/*                    setShowRegisterModel(false);*/}
            {/*                }*/}
            {/*                }> <LoginIcon/> &nbsp;&nbsp;&nbsp;  Login  &nbsp;&nbsp;&nbsp;*/}
            {/*                </MenuItem>*/}
            {/*                }*/}
            {/*                {localStorage.getItem("token") != null &&*/}

            {/*                <MenuItem onClick={() => {*/}
            {/*                    localStorage.removeItem("token");*/}
            {/*                }}> <Logout/> &nbsp;&nbsp;&nbsp;  logout  &nbsp;&nbsp;&nbsp;</MenuItem>*/}
            {/*                }*/}
            {/*            </Menu>*/}
            {/*        </Col>*/}
            {/*    </Row>*/}

            {/*    <Row style={{textAlign: 'left'}}>*/}
            {/*        <Col md={{span: 1}} style={{*/}
            {/*            color: "green", display: 'flex', justifyContent: 'center'*/}
            {/*        }}>*/}
            {/*            <img src={props.data.logo} height='100' width='auto'/>*/}
            {/*        </Col>*/}
            {/*        <Col md={{span:8}}>*/}
            {/*            <div style={{*/}
            {/*                display: "flex",*/}
            {/*                paddingLeft: "60px",*/}
            {/*                paddingRight: "60px",*/}

            {/*                paddingBottom: "15px",*/}
            {/*                paddingTop: "15px"*/}
            {/*            }}>*/}
            {/*                <input className=" " placeholder="Search Product" type="text" id="search" style={{*/}
            {/*                    width: "530px", borderRadius: "23px"*/}
            {/*                    ,zIndex:"1002"    , borderTopLeftRadius: "23px"*/}
            {/*                    , padding: "5px", borderRight: "none"*/}
            {/*                }} onKeyUp={(e)=>{if(e.code=="Enter"){search();}}}/>*/}

            {/*            </div>*/}
            {/*            <DemoNavbar {...props} routes={routes}/>*/}
            {/*        </Col>*/}
            {/*    </Row>*/}
            {/*</Container>*/}

            {/*<a href={whatsapp} target="_blank" style={{*/}
            {/*    bottom: "0px",*/}
            {/*    right: "0px",*/}
            {/*    position: "fixed",*/}
            {/*    padding: "20px",*/}
            {/*    margin: "20px",*/}
            {/*    color: "white",*/}
            {/*    borderRadius: "50%",*/}
            {/*    backgroundColor: "#08d251",*/}
            {/*    zIndex: "1000"*/}
            {/*}}>*/}
            {/*    <div>*/}
            {/*        <WhatsAppIcon fontSize="large"/>*/}
            {/*    </div>*/}
            {/*</a>*/}
        </>
    );
}

export default Headers;
