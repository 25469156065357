import {Modal} from "react-bootstrap";
import CloseIcon from "@mui/icons-material/Close";
import Carts from "./Carts";
import React from "react";
import {useTranslation} from "react-i18next";

function CartModel(props) {
    const {t, i18n} = useTranslation();
    return (
        <>
            <Modal show={props.showModel} backdrop="static" size="xl" onHide={() => props.setShowModel(false)}
                   style={{ top: '5vh'}}>
                <Modal.Header>
                    <div  style={{ right: '10px',display: 'flex',width:'100%' }}>
                        <div>
                            <h2>{t('product')}</h2>
                        </div>
                        <div id="left" style={{textAlign: 'right', width: '100%'}}>
                            <CloseIcon fontSize='large' role="button"
                                       style={{backgroundColor: 'rgb(255,0,0)', borderRadius: '50%', right: '0',color:'rgb(255,255,266)'}}
                                       onClick={() => props.setShowModel(false)}/>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body style={{height: '70vh',overflowY:'auto'}} >
                    <Carts pro={props.data}/>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default CartModel;
