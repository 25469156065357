import React from "react";

import CloseIcon from "@mui/icons-material/Close";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

import Container from 'react-bootstrap/Container';
// import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import LanguageIcon from '@mui/icons-material/Language';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import {Col, Row} from "react-bootstrap";
import Button from "@mui/material/Button";
import {ShoppingCart} from "@mui/icons-material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import FavoriteIcon from "@mui/icons-material/Favorite";
import PersonIcon from "@mui/icons-material/Person";
import AccountCircle from "@mui/icons-material/AccountCircle";
import LoginIcon from "@mui/icons-material/Login";
import Logout from "@mui/icons-material/Logout";
import {useHistory} from "react-router";
import toast, {Toaster} from "react-hot-toast";
import Axios from "axios";
import {mainUrl} from "../../../common/common";
import RegisterModel from "../body/customer/register/RegisterModel";
import WashListModel from "../body/washlist/WashListModel";
import LoginModel from "../body/customer/login/LoginModel";
import {NavLink} from "react-router-dom";
import {margin, padding, width} from "@mui/system";

import {useTranslation} from 'react-i18next';
import SearchIcon from '@mui/icons-material/Search';
import DropdownToggle from "react-bootstrap/DropdownToggle";

function Head(props) {

    const {t, i18n} = useTranslation();

    //Start Progress
    const [cartCount, setCartCount] = React.useState(0);
    const [progress, setProgress] = React.useState(0);
    const [progressStatus, setProgressStatus] = React.useState(true);
    const [buffer, setBuffer] = React.useState(10);
    const [showRegisterModel, setShowRegisterModel] = React.useState(false);
    const [showLoginModel, setShowLoginModel] = React.useState(false);
    const [showWashListModel, setShowWashListModel] = React.useState(false);
    const [data, setData] = React.useState([]);

    const progressRef = React.useRef(() => {
    });

    const navigate = useHistory();

    const search = async () => {
        const value = document.getElementById("search").value;
        if (value != "") {
            localStorage.setItem('search', value)
            navigate.push("/search");
        }
    }

    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Header': '*',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
    React.useEffect(() => {
        setInterval(() => {
            setCartCount(Number(localStorage.getItem('cart')));
        }, 100);
    }, [])
    React.useEffect(() => {
        progressRef.current = () => {
            if (progress > 100) {
                setProgressStatus(false);
            } else {
                const diff = Math.random() * 10;
                const diff2 = Math.random() * 10;
                setProgress(progress + diff);
                setBuffer(progress + diff + diff2);
            }
        };
    });
    React.useEffect(() => {
        const timer = setInterval(() => {
            progressRef.current();
        }, 100);
        return () => {
            clearInterval(timer);
        };
    }, []);

    //End  Progress

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorElLanguage, setAnchorElLanguage] = React.useState(null);
    const [anchorEla, setAnchorEla] = React.useState(null);
    const openLanguage = Boolean(anchorElLanguage);
    const open = Boolean(anchorEl);
    const opena = Boolean(anchorEla);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClicka = (event) => {
        setAnchorEla(event.currentTarget);
    };
    const handleClickLanguage = (event) => {
        setAnchorElLanguage(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleClosea = () => {
        setAnchorEla(null);
    };
    const handleCloseLanguage = () => {
        setAnchorElLanguage(null);
    };
    const notifyError = (message) => toast.error(message, {
        duration: 6000,
    });
    const notifySuccess = (message) => toast.success(message, {
        duration: 6000,
    });
    const closeAll = () => {
        handleClose();
        handleClosea();
        handleCloseLanguage(); //
    }
    // const whatsapp = "https://wa.me/" + props.data.whatsapp_link + "?text=سلام";
    const whatsapp = "https://web.whatsapp.com/send?phone=" + props.data.whatsapp_link ;
    const getCart = async () => {
        if (localStorage.getItem("token") != null) {
            await Axios.post(mainUrl + "getCart", {
                token: JSON.parse(localStorage.getItem("token")),
                'lang': localStorage.getItem("lang")
            }, {headers}).then(res => {
                setData(res.data);
                setShowWashListModel(true);
            }).catch(function (error) {
                notifyError(error.response.data.message);
            });
        } else {
            alert('Please Login');
        }
    };
    const showSearch = () => {
        document.getElementById('searchblock').style.border = "1px solid gray";
        document.getElementById('search').style.display = "block";
        document.getElementById('closeButton').style.display = "block";
        document.getElementById('searchButton').style.display = "none";
    }
    const hideSearch = () => {
        document.getElementById('searchblock').style.border = "none";
        document.getElementById('search').style.display = "none"
        document.getElementById('closeButton').style.display = "none"
        document.getElementById('searchButton').style.display = "block"
    }
    const selectLanguage = (language) => {
        // localStorage.setItem('dir',language);
        localStorage.setItem('lang', language);
        localStorage.setItem('dir', language);
        setAnchorElLanguage(null);
        window.location.reload();
    }
    return (
        <>
            <Head1/>
            <Toaster position="top-right" reverseOrder={false}/>
            <RegisterModel setShowLoginModel={setShowLoginModel} setShowRegisterModel={setShowRegisterModel} showRegisterModel={showRegisterModel}/>
            <WashListModel setShowWashListModel={setShowWashListModel} showWashListModel={showWashListModel}
                           data={data}
                           setData={setData}/>
            <LoginModel setShowRegisterModel={setShowRegisterModel} setShowLoginModel={setShowLoginModel} showLoginModel={showLoginModel}/>

            <Navbar collapseOnSelect expand="lg" style={{borderBottom: "1px solid black"}}>
                <Container>
                    {/*<Navbar.Brand href="/" >*/}
                    {/*    /!*<img src={props.data.logo} height='50' width='auto'/>*!/*/}
                    {/*</Navbar.Brand>*/}
                    <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
                    <Navbar.Collapse id="responsive-navbar-nav" style={{justifyContent: 'center'}}>
                        <Nav className="">
                            {/*<Nav.Link href="#features">Features</Nav.Link>*/}
                            {/*<Nav.Link className="text-info d-none d-md-block d-sm-block" href="#pricing">Pricing</Nav.Link>*/}
                            {props.routes.map((prop, key) => {
                                if (prop.children != null) {
                                    return (
                                        <div
                                            key={key} className="dropdown  "
                                            style={{textAlign: 'center', whiteSpace: 'nowrap'}}>
                                            <div className="dropbtn nav-link page"  style={{
                                                textAlign: 'center',
                                                whiteSpace: 'nowrap',
                                                zIndex: "10002",
                                                color: 'white'
                                            }}>
                                                <h6>{t(prop.name)}</h6>
                                            </div>
                                            <div  className="dropdown-content">
                                                <NavLink key="12" title={prop.title}
                                                         style={{textAlign: 'center', whiteSpace: 'nowrap', zIndex: "10002", color: 'white'}}
                                                         to={prop.children[0].layout + prop.children[0].path}
                                                         className="nav-link"
                                                         activeClassName="actives">
                                                    <h6>
                                                        {t(prop.children[0].name)}
                                                    </h6>
                                                </NavLink>
                                                <NavLink key="123" title={prop.title}
                                                         style={{textAlign: 'center', whiteSpace: 'nowrap', zIndex: "10002", color: 'white'}}
                                                         to={prop.children[1].layout + prop.children[1].path}
                                                         className="nav-link"
                                                         activeClassName="actives">
                                                    <h6>
                                                        {t(prop.children[1].name)}
                                                    </h6>
                                                </NavLink>
                                                <NavLink key="322" title={prop.title}
                                                         style={{textAlign: 'center', whiteSpace: 'nowrap', zIndex: "10002", color: 'white'}}
                                                         to={prop.children[2].layout + prop.children[2].path}
                                                         className="nav-link"
                                                         activeClassName="actives">
                                                    <h6>
                                                        {t(prop.children[2].name)}
                                                    </h6>
                                                </NavLink>
                                                
                                            </div>
                                        </div>
                                    );
                                } else {
                                    if (prop.hide != "true")
                                        return (
                                            <div key={key} className="page "
                                                 style={{textAlign: 'center', whiteSpace: 'nowrap'}}>
                                                <NavLink key={key} title={prop.title}
                                                         style={{
                                                             textAlign: 'center',
                                                             whiteSpace: 'nowrap',
                                                             zIndex: "10002",
                                                             color: 'white'
                                                         }}
                                                         to={prop.layout + prop.path}
                                                         className="nav-link"
                                                         activeClassName="actives">
                                                    <h6>
                                                        {t(prop.name)}
                                                    </h6>
                                                </NavLink>
                                            </div>
                                        );
                                    else
                                        return (
                                            <NavLink key={key} to={prop.layout + prop.path}>
                                            </NavLink>
                                        );
                                }
                            })}
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <div className="d-md-none d-lg-none d-flex " style={{paddingLeft: '20x', paddingRight: '20x', paddingTop: '12px'}}>
                <SearhcS1/>
            </div>

            <a href={whatsapp} target="_blank" style={{
                bottom: "0px",
                right: "0px",
                position: "fixed",
                padding: "10px",
                margin: "30px",
                color: "white",
                borderRadius: "50%",
                backgroundColor: "#08d251",
                zIndex: "1000"
            }}>
                <div>
                    <WhatsAppIcon fontSize="large"/>
                </div>
            </a>
        </>
    );

    function Head1() {
        return (
            <div className="row" style={{display: "inline-flex", width: "100%",}}>
                <div className="col">
                    <a href="https://mdf.afghaninvest.co/">
                        <img src={props.data.logo} height='70' width='auto'
                         style={{paddingLeft: '20px', paddingTop: '10px'}}/>
                    </a>
                </div>
                <div className="col d-none d-md-flex d-lg-flex" style={{paddingLeft: '20x', paddingRight: '20x', paddingTop: '12px'}}>
                    <SearhcS1/>
                </div>
                <div className="col" style={{float: 'right', marginLeft: 'auto', paddingTop: '12px'}}>
                    <Lar/>
                </div>
            </div>
        );
    }

    function Lar() {
        return (
            <div>
                <Row style={{width: "100%"}}>
                    {/*<Col  style={{paddingLeft:"50px",paddingRight:"50px"}}>*/}
                    {/*    <img src={props.data.logo} height='50' width='auto'/>*/}
                    {/*</Col>*/}
                    <Col>
                        <Col id="headerrtl" style={{display: 'flex', justifyContent: 'right',}}>
                            {/**/}
                            <div className="dropdown "
                                 style={{textAlign: 'center', whiteSpace: 'nowrap', marginTop: '7px'}}>
                                <div className="dropbtn nav-link page"
                                     style={{
                                         textAlign: 'center',
                                         whiteSpace: 'nowrap',
                                         zIndex: "10002",
                                         color: 'white',
                                         padding:'5px'
                                     }}>
                                    <span style={{fontSize: '13pt', color: "white"}}>
                                        <LanguageIcon style={{fontSize: '24pt', color: "white"}}/>
                                        <i className=" fa fa-caret-down" style={{fontSize: '14pt', color: "white"}}> </i> &nbsp;
                                    </span>
                                </div>
                                <div className="dropdown-content" style={{width: '200px'}}>
                                    <button className="btn text-dark bg-light"
                                            style={{fontSize: '10pt', width: '100%', margin: '0px'}} role="button"
                                            onClick={() => {
                                                selectLanguage('fa')
                                            }}>
                                        فارسی
                                    </button>
                                    <br/>
                                    <button className="btn text-dark bg-light"
                                            style={{fontSize: '10pt', width: '100%', margin: '0px'}} role="button"
                                            onClick={() => {
                                                selectLanguage('pa')
                                            }}>
                                        پشتو
                                    </button>
                                    <br/>
                                    <button className="btn text-dark bg-light"
                                            style={{fontSize: '10pt', width: '100%', margin: '0px'}} role="button"
                                            onClick={() => {
                                                selectLanguage('en')
                                            }}>
                                        Englsih
                                    </button>
                                </div>
                            </div>
                            {/**/}

                            <div className="dropdown "
                                 style={{textAlign: 'center', whiteSpace: 'nowrap', marginTop: '7px'}}>
                                <div className="dropbtn nav-link page"
                                     style={{
                                         textAlign: 'center',
                                         whiteSpace: 'nowrap',
                                         zIndex: "10002",
                                         color: 'white',
                                         padding:'5px'
                                     }}>
                                    <span style={{fontSize: '13pt', color: "white"}}>
                                     <ShoppingCart style={{fontSize: '22pt', color: "white"}}/>
                                        &nbsp;
                                        <i className=" fa fa-cart" style={{color: "white"}}> </i>
                                        {/*{t('cart')}*/}
                                </span>
                                </div>
                                <div className="dropdown-content">
                                    <button className="btn text-dark bg-light"
                                            style={{fontSize: '10pt', width: '100%', margin: '0px'}} role="button"
                                            onClick={() => {
                                                closeAll();
                                                setShowLoginModel(false);
                                                setShowRegisterModel(false);
                                                getCart();
                                            }} >

                                        <span style={{float: 'left'}}>{t('cart')}</span>
                                        <span style={{paddingLeft:'10px'}}>
                                            <div style={{
                                                display: 'flex',
                                                backgroundColor: '#1da9e1',
                                                color: '#fff',
                                                float: 'right',
                                                borderRadius: '10px 10px 10px 10px'
                                            }}> &nbsp; {cartCount} &nbsp;</div>
                                        </span>
                                        
                                    </button>
                                </div>
                            </div>


                            <div className="dropdown "
                                 style={{textAlign: 'center', whiteSpace: 'nowrap', marginTop: '7px'}}>
                                <div className="dropbtn nav-link page"
                                     style={{
                                         textAlign: 'center',
                                         whiteSpace: 'nowrap',
                                         zIndex: "10002",
                                         color: 'white',
                                         padding:'5px'
                                     }}>
                                    <span style={{fontSize: '13pt', color: "white"}}>
                                       &nbsp; <PersonIcon style={{fontSize: '13pt', color: "white"}}/>&nbsp;
                                        {JSON.parse(localStorage.getItem("token")) != null ? "hi, "+ JSON.parse(localStorage.getItem("token")).username : t('account')}
                                        &nbsp;
                                </span>
                                </div>
                                <div className="dropdown-content">
                                    {localStorage.getItem("token") == null &&
                                    <>
                                        <button className="btn text-dark bg-light"
                                                style={{fontSize: '10pt', width: '100%', margin: '0px'}} role="button"
                                                onClick={() => {
                                                    closeAll();
                                                    setShowLoginModel(false);
                                                    setShowRegisterModel(true);
                                                }}>
                                            <span style={{float: 'left'}}><AccountCircle style={{fontSize: '13pt', color: "black"}}/></span>
                                            <span style={{paddingLeft:'10px'}}>{t('register')}</span>

                                        </button>
                                        <br/>
                                        <button className="btn text-dark bg-light"
                                                style={{fontSize: '10pt', width: '100%', margin: '0px'}} role="button"
                                                onClick={() => {
                                                    closeAll();
                                                    setShowLoginModel(true);
                                                    setShowRegisterModel(false);
                                                }}>
                                            <span style={{float: 'left'}}><LoginIcon style={{fontSize: '13pt', color: "black"}}/></span>
                                            <span style={{paddingLeft:'10px'}}>{t('login')}</span>
                                        </button>
                                    </>
                                    }
                                    {localStorage.getItem("token") != null &&
                                        <>
                                            <button className="btn text-dark bg-light"
                                                    style={{fontSize: '10pt', width: '100%', margin: '0px'}} role="button"
                                                    onClick={() => {
                                                        closeAll();
                                                        setShowLoginModel(false);
                                                        setShowRegisterModel(true);
                                                    }}>
                                                <span style={{float: 'left'}}><AccountCircle style={{fontSize: '13pt', color: "black"}}/></span>
                                                <span style={{paddingLeft:'10px'}}>{t('profile')}</span>

                                            </button>
                                            <br/>

                                            <button className="btn text-dark bg-light"
                                                    style={{fontSize: '10pt', width: '100%', margin: '0px'}} role="button"
                                                    onClick={() => {
                                                        closeAll();
                                                        localStorage.removeItem("token");
                                                        window.location.reload();
                                                    }}>
                                                <span style={{float: 'left'}}><Logout style={{fontSize: '13pt', color: "black"}}/></span>
                                                <span style={{paddingLeft:'10px'}}>{t('logout')}</span>
                                            </button>
                                        </>

                                    }
                                </div>
                            </div>

                        </Col>
                    </Col>
                </Row>
            </div>
        )
    }

    function SearchBar() {
        return (
            <div>
                <div id="searchblock" style={{
                    display: 'flex',
                    marginTop: '5px',
                    backgroundColor: 'white',
                    width: '70vw',
                    justifyContent: 'center'
                }} title={t('searchproduct')}>
                    <input placeholder={t('searchproduct')} type="text" id="search" title={t('searchproduct')}
                           style={{
                               border: 'none',
                               width: '100%',
                               minWidth: "100px",
                               padding: "5px",
                               marginTop: "5px",
                               borderRadius: '10px',
                               maxHeight: "30px!important"
                               ,
                               fontSize: "10pt"
                           }}
                           onKeyUp={(e) => {
                               if (e.code == "Enter") {
                                   search();
                               }
                           }}/>&nbsp;
                    <span role="button" id="searchButton"><SearchIcon
                        style={{fontSize: "20pt", marginTop: "10px"}} title="Search Product"/></span>
                    {/*<span role="button" style={{ marginTop: "10px"}} id="closeButton"*/}
                    {/*      onClick={() => hideSearch()}><CloseIcon*/}
                    {/*    style={{fontSize: "20pt", marginTop: "10px"}}/></span>&nbsp;*/}
                </div>
            </div>
        );
    }

    function SearhcS1() {
        return (
            <div className="form-wrapper " style={{display: 'flex',height:'50px'}}>
                <input id="search" style={{backgroundColor: 'white'}} type="text" onKeyUp={(e) => {
                    if (e.code == "Enter") {
                        search();
                    }
                }} placeholder={t('splaceholder')} required/>
                <button className="hover">{t('search')}</button>
            </div>
        );
    }
}

export default Head;
