import React, {useEffect, useState} from 'react';
import 'react-slideshow-image/dist/styles.css';

import './Homes.css'
import Skeleton from "@mui/material/Skeleton";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Axios from "axios";
import {headers, mainUrl} from "./../../../../common/common";
import {useHistory} from "react-router-dom";
import CartModel from "../cart/CartModel";
import SubCategory from "../subCategory/SubCategory";
import {useTranslation} from "react-i18next";
import CategoryProduct from "../subCategory/CategoryProduct";

function Homes(props) {

    const {t, i18n} = useTranslation();

    const [subCategoryList, setSubCategoryList] = useState([]);
    const [subCategoryStatus, setSubCategoryStatus] = useState(false);
    const [showModel, setShowModel] = useState(false);
    const [data, setData] = useState({});
    useEffect(() => {
        LoadSubCategory();
    }, []);

    function getSubCategory() {
        return Axios.post(mainUrl + "getCatProducts",{'lang':localStorage.getItem("lang")}, {headers});
    }

    const LoadSubCategory = async () => {
        await Promise.all([getSubCategory()])
            .then(function (results) {
                setSubCategoryList(results[0].data);
                setSubCategoryStatus(true);
            }).catch(function (thrown) {
                if (Axios.isCancel(thrown)) {
                    console.log('Request canceled', thrown.message);
                } else {
                    // handle error
                }
            });
        ;
    }

    const history = useHistory();
    return (
        <>
            <CartModel data={data} setShowModel={setShowModel} showModel={showModel}/>
            <Category item={props}/>
            <CategoryProduct item={props} subCategoryStatus={subCategoryStatus} subCategoryList={subCategoryList}
                         setData={setData} setShowModel={setShowModel}/>
        </>
    );

    function Category(pro) {
        return (
            <>
                {
                    props.categoryListStatus == false &&
                    <Row className="p-xxl-5" style={{display: 'flex', marginRight: '0', justifyContent: 'center'}}>
                        {[1, 2, 3, 4, 5, 6].map((e, index) => (
                            <Col key={index} className="col-md-4 p-xxl-2">
                                <Skeleton variant="rectangular" color='red' animation='pulse' height={200}/>
                            </Col>
                        ))}
                    </Row>
                }

                {
                    props.categoryListStatus == true &&
                    <Row style={{justifyContent: 'center'}}>
                        {
                            props.categoryList.map((e, index) => (
                                <Col  key={index}>
                                    <div style={{minWidth:'600px',maxWidth:'650px',textDecoration: 'none', margin: 'auto', textAlign: 'center'}}
                                         role='button' onClick={() => {
                                        localStorage.setItem("categoryId", e.id);
                                        history.push('/detail');
                                    }}
                                    >
                                        <Row style={{margin:'10px',backgroundColor: e.background_color,borderRadius:"10px",marginBottom:'10px',boxShadow:'0px 0px 3px black'}}>

                                            {
                                                <Col md={12}>
                                                <Row>
                                                    <Col md={6} style={{
                                                        textAlign: 'center',
                                                        marginTop: '2%'
                                                    }}>
                                                        <h3 className="text-center text-uppercase text-info ">{e.name}</h3>
                                                        <p style={{
                                                            padding: '20px',
                                                            textAlign: 'justify',
                                                            maxHeight:'208px',
                                                            overflowY:'hidden'
                                                        }}>
                                                            {e.description}
                                                        </p>

                                                        <Row style={{justifyContent: 'center'}}>
                                                            <div
                                                                role='button' onClick={() => {
                                                                localStorage.setItem("categoryId", e.id);
                                                                history.push('/detail');
                                                            }}
                                                                style={{width: '100px'}} className="btn btn-info">
                                                                {/*view*/}
                                                                {t('view')}
                                                            </div>
                                                        </Row>
                                                    </Col>
                                                    <Col md={5}>
                                                        <img src={e.image}  width='400' height='auto'
                                                             style={{padding: '15px'}}
                                                        />
                                                    </Col>
                                                </Row>
                                                </Col>
                                            }
                                        </Row>
                                    </div>
                                </Col>
                            ))
                        }
                    </Row>
                }
            </>
        );
    }
}

export default Homes;
