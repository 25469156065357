import React, {useState} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";


function Details(props) {
    const [quantity, setQuantity] = useState(0);

    return (
        <>
            <Row style={{overflowY:"auto",height: '700px'}}>
                <Col md={12} >
                    <div dangerouslySetInnerHTML={{__html: props.detail}}></div>
                </Col>
            </Row>
        </>
    );
}

export default Details;
