import React, {useEffect, useState} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Skeleton from "@mui/material/Skeleton";
import Axios from "axios";
import {headers, mainUrl} from "./../../../../common/common";
import {Slide} from "react-slideshow-image";
import CartModel from "../cart/CartModel";
import SubCategory from "../subCategory/SubCategory";
import {useHistory} from "react-router-dom";

function CategoryBody(props) {
    const [subCategoryList, setSubCategoryList] = useState([]);
    const [subCategoryStatus, setSubCategoryStatus] = useState(false);
    const [showModel, setShowModel] = useState(false);
    const [data, setData] = useState({});
    useEffect(() => {
        LoadSubCategory();
    }, []);

    function getSubCategory() {
        return Axios.post(mainUrl + "getSubCategorysOne", {
            'category_id': localStorage.getItem("categoryId"),
            'lang':localStorage.getItem("lang")
        }, {headers});
    }

    const LoadSubCategory = async () => {
        await Promise.all([getSubCategory()])
            .then(function (results) {
                setSubCategoryList(results[0].data);
                setSubCategoryStatus(true);
            }).catch(function (thrown) {
                if (Axios.isCancel(thrown)) {
                    console.log('Request canceled', thrown.message);
                } else {
                }
            });
        ;
    }


    return (
        <>

            <CartModel data={data} setShowModel={setShowModel} showModel={showModel}/>
            {/*Sub Category List */}
            <Category item={props}/>

             {/*Sub Category List with some product*/}
             <SubCategory item={props} subCategoryStatus={subCategoryStatus} subCategoryList={subCategoryList}
                         setData={setData} setShowModel={setShowModel}/>
        </>
    );

    function Category(pro) {

        return (
            <>
                {pro.item.categoryListStatus == false &&
                <>
                    <div className="col-md-12" style={{display: 'flex', marginRight: '0', justifyContent: 'center'}}>
                        <Skeleton variant="rectangular" color='red' animation='pulse' height={350} width='100%'/>
                    </div>
                    <Row className="p-xxl-2 col-md-12" style={{display: 'flex', justifyContent: 'center'}}>
                        <Col className="p-xxl-2 col-md-2" style={{maxWidth: '300px'}}>
                            <Skeleton variant="rectangular"
                                      color='red' animation='pulse'
                                      height={40}/>
                        </Col>
                    </Row>
                    <Row className="" style={{display: 'flex', marginRight: '0', justifyContent: 'center'}}>
                        {[1, 2, 3, 4, 5].map((e, index) => (
                            <Col key={index} className="col-md-1 " style={{display: 'flex', justifyContent: 'center'}}>
                                <Skeleton variant="rectangular" color='red' animation='pulse'
                                          style={{borderRadius: '50% 50% 50% 50%'}} height={150} width={150}/>
                            </Col>
                        ))}
                    </Row>
                </>
                }
                {pro.item.categoryListStatus == true &&
                <>
                    <div className="col-md-12">
                        <div style={{
                            textAlign: 'center',
                            backgroundImage: `url(` + pro.item.categoryDetailList.category[0].image + `)`,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            display: "block",
                            height: "400px"
                        }}>
                        </div>
                        {/*<img src={pro.item.categoryDetailList.category[0].image} height='auto' width='100%'/>*/}
                    </div>
                    <Row className="p-xxl-2 col-md-12 " style={{textAlign: 'center'}}>
                     <span className="h2 text-uppercase text-success">
                         {pro.item.categoryDetailList.category[0].name}
                     </span>

                        <span className="" style={{
                            maxWidth: ' 100px',
                            borderTop: '7px solid #90ee90',
                            display: ' block',
                            margin: '0 auto'
                        }}>&nbsp;</span>

                    </Row>
                    <Row style={{justifyContent: 'center', display: 'flex'}}>
                        <Row style={{
                            display: 'flex',
                            justifyContent: 'center',
                            maxWidth: '1200px',
                            textAlign: 'justify'
                        }}>
                            <Col className="pr-3" style={{width: '1000px!important'}}>
                                 <span className="" style={{textAlign:'justify'}}>
                         {pro.item.categoryDetailList.category[0].description}
                                 </span>
                                <SlideStep values={pro.item}/>
                            </Col>
                        </Row>
                    </Row>
                    <Row style={{justifyContent: 'center', display: 'flex'}}>
                        <br/>
                        <br/>
                    </Row>
                </>
                }
            </>
        );
    }

    function SlideStep(vp) {
        let subC, le, slideList = [];
        if (vp.values.categoryDetailList.category != null) {
            subC = (vp.values.categoryDetailList.subcategory);
            le = subC.length / 5;
            slideList = [];
            for (let r = 0; r < subC.length; r = r + 5) {
                slideList.push(r);
            }
        }
        return (
            <div className="slide-container">
                <Slide>
                    {
                        slideList.map((i, inx) => {
                            return (
                                <SlideShowItem key={i} subC={subC} i={i}/>
                            );
                        })
                    }
                </Slide>
            </div>
        );
    }

    function SlideShowItem(val) {
        const history = useHistory();
        return (
            <div className="each-slide"
                 style={{display: 'flex', justifyContent: 'center', white: 'auto', overflowY: 'auto'}}>
                {[0, 1, 2, 3].map((e, index) => {
                    e = e + val.i;
                    return (
                        <Col key={e} className=" "
                             style={{display: 'inline', justifyContent: 'center'}}>
                            {val.subC[e] != null &&

                            <div style={{
                                padding: '20px'
                            }}>
                                <div className="">
                                    <img role='button' className=" shadow bg-white" src={val.subC[e].image}
                                         style={{

                                             // border: '5px solid rgb(144, 238, 144)'
                                         }}
                                         onClick={() => {
                                             console.log(val.subC[e].id);
                                             localStorage.setItem("subCategoryId", val.subC[e].id);
                                             history.push('/all_product');

                                         }}
                                         height="auto" width="auto"/>
                                </div>
                                <div className=" col-md-12" style={{paddingTop:"20px"}}>
                                    {val.subC[e].name}
                                </div>
                            </div>
                            }
                        </Col>
                    );
                })}
            </div>
        );
    }

}

export default CategoryBody;
