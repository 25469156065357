import Product from "./views/order-web/body/product/Product";
import CategoryPart from "./views/order-web/body/category/CategoryPart";
import SubCategoryPart from "./views/order-web/body/subCategory/SubCategoryPart";
import Stories from "./views/order-web/body/stories/Stories";
import AboutUs from "./views/order-web/body/page/AboutUs";
import ProductUs from "./views/order-web/body/productus/ProductUs";
import FAQ from "./views/order-web/body/page/FAQ";
import PrivacyPolicy from "./views/order-web/body/page/PrivacyPolicy";
import TermsService from "./views/order-web/body/page/TermsService";
import SearchProduct from "./views/order-web/body/search/SearchProduct";
import UsProduct from "./views/order-web/body/productus/UsProduct";
import PaymentPolicy from "./views/order-web/body/page/PaymentPolicy";
import ShippingPolicy from "./views/order-web/body/page/ShippingPolicy";
import ReturnPolicy from "./views/order-web/body/page/ReturnPolicy";
import ExpressDelivery from "./views/order-web/body/page/ExpressDelivery";
import HourSupport from "./views/order-web/body/page/HourSupport";
import SitePayment from "./views/order-web/body/page/SitePayment";
import BackGuarantee from "./views/order-web/body/page/BackGuarantee";
import GuaranteeGoods from "./views/order-web/body/page/GuaranteeGoods";

var routes = [


    {
        path: "/product",
        name: "product",
        title: '',
        icon: "nc-icon nc-bank",
        component: Product,
        layout: "",
    },
    {
        path: "/productus",
        name: "productus",
        title: '',
        icon: "nc-icon nc-bank",
        component: UsProduct,
        layout: "",
    },
    {
        path: "/Stories",
        name: "stories",
        title: '',
        icon: "nc-icon nc-circle-10",
        component: Stories,
        layout: ""
    },
    {
        path: "/about",
        name: "aboutus",
        title: '',
        icon: "nc-icon nc-layout-11",
        component: AboutUs,
        layout: "",
    },
    {
        path: "/detail",
        name: "Detail",
        title: '',
        icon: "nc-icon nc-circle-10",
        component: CategoryPart,
        layout: "",
        hide: "true"
    },
    {
        path: "/search",
        name: "Search",
        title: '',
        icon: "nc-icon nc-circle-10",
        component: SearchProduct,
        layout: "",
        hide: "true"
    },
    {
        path: "/all_product",
        name: "All Product",
        title: '',
        icon: "nc-icon nc-circle-10",
        component: SubCategoryPart,
        layout: "",
        hide: "true"
    },

    {
        path: "/FAQ",
        name: "FAQ",
        title: '',
        icon: "nc-icon nc-circle-10",
        component: FAQ,
        layout: "",
        hide: "true"
    },
    {
        path: "/PrivacyPolicy",
        name: "PrivacyPolicy",
        title: '',
        icon: "nc-icon nc-circle-10",
        component: PrivacyPolicy,
        layout: "",
        hide: "true"
    },
    {
        path: "/TermsService",
        name: "TermsService",
        title: '',
        icon: "nc-icon nc-circle-10",
        component: TermsService,
        layout: "",
        hide: "true"
    }  , {
        path: "/help",
        name: "help",
        title: '',
        icon: "nc-icon nc-circle-10",
        component: PaymentPolicy,
        layout: "",
        hide: "false",
        children: [
            {
                path: "/paymentpolicy",
                name: "PaymentPolicy",
                component: PaymentPolicy, layout: "", title: '', hide: "true",
            },
            {
                path: "/shippingpolicy",
                name: "ShippingPolicy",
                component: ShippingPolicy, layout: "", title: '', hide: "true",
            },
            {
                path: "/returnpolicy",
                name: "ReturnPolicy",
                component: ReturnPolicy, layout: "", title: '', hide: "true",
            }
        ]
    },{
        path: "/paymentpolicy",
        name: "PaymentPolicy",
        component: PaymentPolicy,
        layout: "", hide: "true"
    },
    {
        path: "/shippingpolicy",
        name: "ShippingPolicy",
        component: ShippingPolicy,
        layout: "", hide: "true"
    },
    {
        path: "/returnpolicy",
        name: "ReturnPolicy",
        component: ReturnPolicy,
        layout: "", hide: "true"
    },

    {
        path: "/ExpressDelivery",
        name: "ExpressDelivery",
        component: ExpressDelivery,
        layout: "", hide: "true"
    },
    {
        path: "/HourSupport",
        name: "HourSupport",
        component: HourSupport,
        layout: "", hide: "true"
    },
    {
        path: "/SitePayment",
        name: "SitePayment",
        component: SitePayment,
        layout: "", hide: "true"
    },
    {
        path: "/BackGuarantee",
        name: "BackGuarantee",
        component: BackGuarantee,
        layout: "", hide: "true"
    },
    {
        path: "/GuaranteeGoods",
        name: "GuaranteeGoods",
        component: GuaranteeGoods,
        layout: "", hide: "true"
    },
    {
        path: "/",
        name: "hom",
        title: '',
        icon: "nc-icon nc-bank",
        component: Product,
        layout: "",
        hide: "true"
    }

];

export default routes;
