import React, {useEffect} from "react";
import Axios from "axios";
import {headers, mainUrl} from "../../../../common/common";
import SubCategoryBody from "./SubCategoryBody";

function SubCategoryPart() {

    const [allProduct, setAllProduct] = React.useState([]);
    const [allProductStatus, setAllProductStatus] = React.useState(false);

    useEffect(() => {
        LoadMenu();
    }, []);

    function getAllProduct() {
        return Axios.post(mainUrl + "getAllProduct?page=1",
            {
                'sub_category_id': localStorage.getItem("subCategoryId"),
                'lang': localStorage.getItem("lang")
            }, {headers});
    }

    const LoadMenu = async () => {
        await Promise.all([getAllProduct()])
            .then(function (results) {
                setAllProduct(results[0].data);
                setAllProductStatus(true);
            }).catch(function (thrown) {
                if (Axios.isCancel(thrown)) {
                    console.log('Request canceled', thrown.message);
                } else {
                    // handle error
                }
            });
        ;
    }

    return (
        <>
            <SubCategoryBody
                allProduct={allProduct}
                allProductStatus={allProductStatus}
                setAllProduct={setAllProduct}
            />
        </>
    );
}

export default SubCategoryPart;
