
const farsiWords= {

    "ExpressDelivery":"تحویل سریع",
    "HourSupport":" پشتیبانی 24 ساعته",
    "SitePayment":"پرداخت در محل",
    "BackGuarantee":" 7 روز ضمانت بازگشت وجه",
    "GuaranteeGoods":"    تضمین اصل بودن کالا",
    "Subject":" موضوع",
    "Help":" چطور میتوانیم کمک کنیم؟",
    "PhoneNumber":"شماره تلفن شما",
    "EmailAddress":"آدرس ایمیل شما",
    "YourName":"اسم شما",
    "ContactNumber":"شماره تماس",
    "ContactEmail":"تماس با ایمیل",
    "Address":"نشانی",

    "profile": "اکونت",

    "ReturnPolicy":"قوانین لغو و بازگشت",
    "ShippingPolicy":"قوانین حمل و نقل",
    "PaymentPolicy":"قوانین پرداخت",
    "help": "کمک",

    "lenpassowd":"طول کلمه عبور باید حداقل هشت کاراکتر باشد.",
    "phoneplace":"مثال., بدون کد کشوری - 789789789",
    "emailplace":"مثال, example@gmail.com",
    "username":"اسم کاربری",
    "passwodpolicy":"رمز عبور باید حداقل 8 کاراکتر باشد!",

    "logindetail":"با ادامه، با شرایط استفاده و اعلامیه حفظ حریم خصوصی Made in Afghanistan موافقت می کنید.",
    "newmade":"جدید Made In Afghanistan ",
    "createmade":"حساب Made in Afghanistan خود را ایجاد کنید",


    "registerdetail":"با ایجاد یک حساب کاربری، با شرایط استفاده در افغانستان و اعلامیه حفظ حریم خصوصی موافقت می کنید.",
    "haveaccount":"از قبل حساب کاربری دارید؟",
    "success":"موفقانه عملیه انجام شد",
    "pllogin":"لطفا اول به سیستم وارد شوید.",

    "addmessage":"موفقانه جنس را که انتخاب کرده اید در لست اضافه شد.",

    "search": "جستجو",
    "splaceholder": " دنبال چی میگردی ؟ ",

    "searchproduct": "جستجو به اساس اسم جنس",
    "addtocart":"به سبد خرید اضافه کنید",
    "productdtail":"جزئیات جنس",
    "cart": "کارت",
    "account": "اکونت",
    "register": "راجستر",
    "login": "وارد شدن",
    "logout": "بیرون شدن",
    "product": "تولیدات",
    "productus": "تولیدات در امریکا",
    "stories": "داستان ها",
    "aboutus": "درباره ما",
    "view": "نمایش",
    "story": "داستان",
    "fpassword": "آیا رمز را فراموش نموده اید؟",
    "email": "ایمل آدرس",
    "password": "پسورد",

    "viewmore": "بیشتر ببینید",

    "registration": "راجستر نمودن یوزر جدید",

    "storeinfo":"تماس",
    "fallowus":"ما را دنبال کنید",
    "termservice":"شرایط استفاده از خدمات",
    "faq":"سوالات متداول",

    "privacypolicy":"سیاست حفظ حریم خصوصی",

    "name":"اسم",
    "lastname":"تخلص",
    "phone":"شماره تلیفون",
    "address":"آدرس",
    "confitmpassword":"پسورد را دوباره درج نماید",
    "confirmmessage":"پسورد ها یکی نیستد",



    "ver1":"ایمیل شما تایید نشده است!",
    "ver2":"لطفا آدرس ایمیل خود را تایید کنید.",
    "ver3":"ما ایمل تائیدی را به این ایمل ارسال نمودیم",
    "ver4":"اگر نمی توانید ایمیل تایید ایمیل را در پوشه فهرست پیدا کنید. لطفا پوشه Junk/Spam را بررسی کنید.",
    "ver5":"اگر ایمیل تایید ایمیل را دریافت نکردید، لطفا روی دکمه ارسال مجدد کلیک کنید.",

    "total":"مجموع",
    "quantity":"تعداد",
    "unittype":"واحد شمارش",
    "products":"جنس",
    "no":"شماره",
    "delete":"حذف",
    "update":"ویرایش"
}


export default farsiWords;
