import React, {useEffect, useState} from "react";
import Col from "react-bootstrap/Col";
import orderLogo from "../../../../assets/img/logo.png";
import Row from "react-bootstrap/Row";
import Axios from "axios";
import {headers, mainUrl} from "../../../../common/common";
import {useTranslation} from "react-i18next";

function AboutUs() {
    const [data, setData] = useState({});
    useEffect(() => {
        getPage();
    }, []);

    function getAboutPage() {
        return Axios.post(mainUrl + "getPage",{"page_name":"about",'lang':localStorage.getItem("lang")}, {headers});
    }

    const getPage = async () => {
        await Promise.all([getAboutPage()])
            .then(function (results) {
                console.log(results[0]);
                setData(results[0].data.data);
            }).catch(function (thrown) {
                if (Axios.isCancel(thrown)) {
                    console.log('Request canceled', thrown.message);
                } else {
                    // handle error
                }
            });
        ;
    }

    const {t, i18n} = useTranslation();
    return (
        <>
            {
                <>
                    <div className="p-xxl-2 offset-2 col-md-8 fs-6"  style={{margin:'0 auto',minHeight:'800px'}}>
                        <h4  style={{color: '#0141a8'}}> {t('aboutus')}</h4>
                        <Row style={{textAlign: 'justify'}}>
                            <Col>

                                {Object.keys(data).length !== 0 &&
                                <div dangerouslySetInnerHTML={{__html: data}}></div>
                                }
                            </Col>
                            <br/>
                        </Row>
                    </div>
                </>
            }
        </>
    );
}

export default AboutUs;
