
const pashtoWords= {

    "ExpressDelivery":"ایکسپریس تحویلي",
    "HourSupport":" 24 ساعته ملاتړ",
    "SitePayment":" په سایټ کې تادیه کول",
    "BackGuarantee":"د 7 ورځو پیسو بیرته تضمین",
    "GuaranteeGoods":"د توکو اصليت تضمین کړئ",
    "Subject":" موضوع",
    "Help":" موږ څنګه مرسته کولی شو؟",
    "PhoneNumber":"ستاسو د تلیفون شمیره",
    "EmailAddress":"ستاسو د بریښنالیک پته",
    "YourName":"ستاسو نوم",
    "ContactNumber":"د اړیکې شمیره",
    "ContactEmail":"د بریښنالیک سره اړیکه ونیسئ",
    "Address":"پته",

    "profile": "اکونت",

    "ReturnPolicy":"د لغوه کولو او بیرته ستنیدو پالیسي",
    "ShippingPolicy":"د بار وړلو پالیسي",
    "PaymentPolicy":"د تادیاتو پالیسي",
    "help": "مرسته",
    "username":"د کارن نوم",
    "passwodpolicy":"پاسورډونه باید لږترلږه 8 حروف ولري!",

    "lenpassowd":"پاسورډونه باید لږترلږه اته حروف اوږدوالی ولري.",
    "phoneplace":"مثال., بدون کد کشوری - 789789789",
    "emailplace":"مثال, example@gmail.com",
    "logindetail":"په دوام ورکولو سره، تاسو موافق یاست چې په افغانستان کې جوړ شوي د کارولو شرایط او د محرمیت خبرتیا.",
    "newmade":"جدید Made In Afghanistan ",
    "createmade":"خپل په افغانستان کې جوړ اکاونټ جوړ کړئ",

    "haveaccount":"ایا دمخه حساب لرئ؟",

    "registerdetail":"د یو حساب په جوړولو سره، تاسو موافق یاست چې په افغانستان کې جوړ شوي د کارولو شرایط او د محرمیت خبرتیا.",
    "success":"په کامیابی سره کار تر سره شو",
    "pllogin":"لطفآ! اول سیستم ته داخل شي.",
    "addmessage":"ستاسو انتخاب شوی جنس په کامیابی سره لیست ته آضافه شولي.",

    "addtocart":" د اخیستلو ټوکرۍ ته اضافه کړی ",
    "productdtail":"د توکی په اړه  معلومات",

    "search": "پلټنه",
    "splaceholder": "د څه شي په لټه کي یاست؟ ",

    "searchproduct": "د توکی یا جنس د نوم په اساس پلټنه",
    "cart": "ټوکرۍ",
    "account": "حساب",
    "register": "راجستر",
    "login": "داخلیدل",
    "logout": "خارجیدل",
    "product": "تولیدات",
    "productus": "تولیدات په امریکا کی",
    "stories": "قیصي",
    "aboutus": "زموژ په اړه",
    "view": "نمایش",
    "viewmore": "ډیر څه وګوری",
    "story": "ًقیصه",

    "fpassword": "آیا! پټ ټوری مو له یاده وتلۍ؟",
    "email": "بریشنا لیک",
    "password": "پټ توری",

    "registration": "د نوی استفاده کوونکی  ثبت کول",

    "termservice":"خدماتو څخه د استفادی شرایط",
    "faq":"متعارفی پوختنی",

    "fallowus":"مو‌ژ تعقیب کړی",
    "privacypolicy":"د خصوصی حریم کړنلاره",
    "storeinfo":"اړیکه",

    "name":"نوم",
    "lastname":"تخلص",
    "phone":"د تلیفون شماره",
    "address":"پته",
    "confitmpassword":"پټ توری بیا ځلی ډاخل کړی",
    "confirmmessage":"پټ توری یا پسورد سمون نلری",


    "ver1":"ستاسو بریشنالیک تاييد شوی ندی!",
    "ver2":" مهربانی وکړی خپل بریشننالیک مو تایید کړی.",
    "ver3":"موژه د تاییدی بریشنالیک دغه بریشنالیک ته استولی دی",
    "ver4":"که چیری نشی کولی د تاییدی بریشنالیک د فهرست په کڅوړه کی پیدا کړی نو د Spam/Junk کڅوړه وپلټی .",
    "ver5":"که د تاییدی بریشنالیک مو ترلاسه کړی نه وی نو مهربانی وکړی په لاندی دبیا لیژلو تڼی وکاژی .",

    "total":"مجموع",
    "quantity":"تعداد",
    "unittype":"د واحد بیلګه",
    "products":"توکی",
    "no":"شماره",
    "delete":"پاکول",
    "update":"تازه کول"
}


export default pashtoWords;
