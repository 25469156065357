import React, {useEffect, useState} from "react";
import PerfectScrollbar from "perfect-scrollbar";
import {Route, Switch, useLocation} from "react-router-dom";
import Footer from "components/Footer/Footer.js";
import routes from "routes.js";
import {Redirect} from "react-router";
import Headers from "../views/order-web/header/Headers";
import Axios from "axios";
import {headers, mainUrl} from "../common/common";

var ps;
function Dashboard(props) {
    const [data, setData] = useState({});
    useEffect(() => {
        if(localStorage.getItem("lang")==null){
            localStorage.setItem("lang","en");
            window.location.reload();
        }
        if(localStorage.getItem("dir")==null){
            localStorage.setItem("dir","en");
            window.location.reload();
        }
        load();
    }, []);

    function getSetup() {
        return Axios.get(mainUrl + "getSetup", {headers});
    }

    const load = async () => {
        await Promise.all([getSetup()])
            .then(function (results) {
                setData(results[0].data);
            }).catch(function (thrown) {
                if (Axios.isCancel(thrown)) {
                } else {
                }
            });
        ;
    }

    const mainPanel = React.useRef();
    const location = useLocation();
    React.useEffect(() => {
        if (navigator.platform.indexOf("Win") > -1) {
            ps = new PerfectScrollbar(mainPanel.current);
            ps.destroy();
            document.body.classList.toggle("perfect-scrollbar-on");
        }
        return function cleanup() {
            if (navigator.platform.indexOf("Win") > -1) {
                ps.destroy();
                document.body.classList.toggle("perfect-scrollbar-on");
            }
        };
    });
    React.useEffect(() => {
        mainPanel.current.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
    }, [location]);

        return (
            <div className="wrapper" style={{minHeight:'100vh'}}>
                <div className="main-panel" style={{position: 'absolute',width: '100%'}} ref={mainPanel}>
                    <div style={{boxShadow:'4px 1px 9px grey' ,  marginBottom: '20px',backgroundColor:'#1da9e1'}}>
                        <Headers  {...props} routes={routes} data={data}/>
                    </div>
                    <Switch>
                        {routes.map((prop, key) => {
                             return (
                                <Route
                                    path={prop.layout + prop.path}
                                     component={() => (<prop.component data={data} />)}
                                    key={key}
                                />
                            );
                        })}
                        <Redirect from="/" to="/product" />
                    </Switch>
                    <Footer fluid routes={routes} data={data} />
                </div>
            </div>
        );
}

export default Dashboard;
