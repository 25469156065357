import {Modal} from "react-bootstrap";
import CloseIcon from "@mui/icons-material/Close";
import Details from "./Details";
import React, {useEffect} from "react";
import Axios from "axios";
import {headers, mainUrl} from "../../../../../common/common";
import {useTranslation} from "react-i18next";

function DetailModel(props) {

    const {t, i18n} = useTranslation();

    return (
        <>
            <Modal show={props.showModel} size="xl" onHide={() => props.setShowModel(false)}
                   style={{ top: '10vh'}}>
                <Modal.Header>
                    <div  style={{ right: '10px',display: 'flex',width:'100%' }}>
                        <div>
                            <h2>{t('story')}</h2>
                        </div>
                        <div id="left" style={{textAlign: 'right', width: '100%'}}>
                            <CloseIcon fontSize='large' role="button"
                                       style={{backgroundColor: 'rgb(255,0,0)', borderRadius: '50%', right: '0',color:'rgb(255,255,266)'}}
                                       onClick={() => props.setShowModel(false)}/>
                        </div>
                    </div>

                </Modal.Header>
                <Modal.Body style={{height: '70vh',overflowY:'auto'}}>
                    <Details detail={props.detail}/>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default DetailModel;
