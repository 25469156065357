import {Modal} from "react-bootstrap";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import Registers from "./Registers";
import Login from "../login/Login";
import {useTranslation} from "react-i18next";

function RegisterModel(props) {

    const {t, i18n} = useTranslation();
    return (
        <>
            <Modal show={props.showRegisterModel} size="md" backdrop="static"
                   onHide={() => props.setShowRegisterModel(false)}
                   style={{top: '10vh',overflowY:'auto'}}>
                <Modal.Header>
                    <div  style={{ right: '10px',display: 'flex',width:'100%' }}>
                        <div>
                            <h2 style={{whiteSpace:'nowrap'}}>{t('registration')}</h2>
                        </div>
                        <div id="left" style={{textAlign: 'right', width: '100%'}}>
                            <CloseIcon fontSize='large' role="button"
                                       style={{backgroundColor: 'rgb(255,0,0)', borderRadius: '50%', right: '0',color:'rgb(255,255,266)'}}
                                       onClick={() => props.setShowRegisterModel(false)}/>
                        </div>
                    </div>

                </Modal.Header>
                <Modal.Body style={{overflowY:'auto', backgroundColor:'rgb(180 184 185 / 50%)'}}>
                    <Registers  setShowRegisterModel={props.setShowRegisterModel} setShowLoginModel={props.setShowLoginModel}/>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default RegisterModel;
