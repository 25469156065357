import React, {useEffect} from "react";
import Slides from "./../slide/Slides";
import Homes from "../home/Homes";
import {headers, mainUrl} from "../../../../common/common";
import Axios from "axios";

function Product() {
    const [categoryList, setCategoryList] = React.useState([]);
    const [categoryListStatus, setCategoryListStatus] = React.useState(false);


    useEffect(() => {
        LoadMenu();
    }, []);

    function getCategory() {
        return Axios.post(mainUrl + "getCategorys",{'lang':localStorage.getItem("lang")}, {headers});
    }


    const LoadMenu = async () => {
        await Promise.all([getCategory()])
            .then(function (results) {
                setCategoryList(results[0].data);
                setCategoryListStatus(true);
            }).catch(function (thrown) {
                if (Axios.isCancel(thrown)) {
                    console.log('Request canceled', thrown.message);
                } else {
                    // handle error
                }
            });
        ;
    }

    return (
        <>
            {
                <>
                    <Slides/>
                    <div className="content">
                        {/*<h1 className="text-info text-center" style={{letterSpacing:"2px"}}>Product Categories</h1>*/}
                        <Homes
                            categoryList={categoryList}
                            categoryListStatus={categoryListStatus}
                        />
                    </div>
                </>
            }
        </>
    );
}

export default Product;
