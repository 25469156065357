import React from "react";

const englishWords= {

    "ExpressDelivery":"Express delivery",
    "HourSupport":"24-hour support",
    "SitePayment":"On-site payment",
    "BackGuarantee":"7 days money back guarantee",
    "GuaranteeGoods":"Guarantee the originality of the goods",
    "Subject":"Subject",
    "Help":"How can we help?",
    "PhoneNumber":"Your Phone Number",
    "EmailAddress":"Your Email Address",
    "YourName":"Your Name",
    "ContactNumber":"Contact Number",
    "ContactEmail":"Contact Email",
    "Address":"Address",


    "ReturnPolicy":"Cancellation & Return Policy",
    "ShippingPolicy":"Shipping Policy",
    "PaymentPolicy":"Payment Policy",
    "lenpassowd":"Passwords should be a minimum of eight characters in length.",
    "phoneplace":"e.g., without country code - 789789789",
    "emailplace":"e.g., example@gmail.com",
    "username":"User Name",
    "passwodpolicy":"Passwords must be at least 8 characters!",
    "logindetail":"By continuing, you agree to Made in Afghanistan's Conditions of Use and Privacy Notice.",
    "newmade":"New to Made In Afghanistan",
    "createmade":"Create your Made in Afghanistan account",

    "haveaccount":"Already have an account?",
    "registerdetail":"By creating an account, you agree to Made in Afghanistan's Conditions of Use and Privacy Notice.",
    "success":"Update Successfully.",
    "pllogin":"Please Login.",
    "addmessage":"Added Successfully.",

    "addtocart":"Add to Cart",
    "productdtail":"Product Detail",
    "cart": "Cart",
    "searchproduct": "Search Product",
    "account": "My Account",
    "search": "Search",
    "splaceholder": " What are you looking for? ",
    "register": "Register",
    "profile": "Profile",
    "login": "Log In",
    "loginbutton": "Continue",
    "logout": "Log Out",
    "product": "Product",
    "productus": "United States Product",
    "stories": "Stories",
    "aboutus": "About us",
    "view": "View",
    "help": "Help",
    "viewmore": "View More",
    "story": "Story",
    "fpassword": "Forgot Password",
    "email": "Email Address",
    "password": "Password",
    "registration": "Customer Registration",
    "name":"Name",
    "lastname":"Last Name",
    "phone":"Phone Number",
    "address":"Address",
    "confitmpassword":"Confirm Password",
    "confirmmessage":"The password confirmation does not match.",
    "termservice":"Terms of Service",
    "faq":"FAQ",

    "storeinfo":"Contact",

    "fallowus":"Follow Us",
    "privacypolicy":"Privacy Policy",

    "ver1":"Your email is not verified!",
    "ver2":"please verify your email address.",
    "ver3":"we have sent the verification mail to",
    "ver4":"if you can not find the email verification mail in the index folder. Please check the Junk/Spam folder.",
    "ver5":"if you did not receive the email verification mail please click on the resend button.",


    "total":"Total",
    "quantity":"Quantity",
    "unittype":"Unit Type",
    "products":"Product",
    "no":"No",
    "delete":"Delete",
    "update":"Update"
}


export default englishWords;
