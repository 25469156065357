import React, {useEffect, useState} from 'react';
import {Slide} from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

import './Slides.css'
import Skeleton from "@mui/material/Skeleton";
import Axios from "axios";
import {mainUrl} from "./../../../../common/common";

const slideImages = [
    {
        url: 'https://images.unsplash.com/photo-1509721434272-b79147e0e708?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80',
        caption: 'Slide 1',
        backgroundColor: 'rgba(0,121,2,0.16)'
    },
];

function Slides() {

    const [slideStatus, setSlideStatus] = useState(false);
    const [slideList, setSlideList] = useState([]);
    useEffect(() => {
        LoadSlide();
    }, []);

    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Header': '*',
        // 'Authorization': 'Bearer ' + localStorage.getItem('token')
    }

    function getSlide() {
        return Axios.get(mainUrl + "getSliders", {headers});
    }

    const LoadSlide = async () => {
        await Promise.all([getSlide()])
            .then(function (results) {
                setSlideList(results[0].data);
                setSlideStatus(true);
            }).catch(function (thrown) {
                console.log(thrown);
            });
        ;
    }

    return (
        <div>
            {
                slideStatus == false &&
                <Skeleton variant="rectangular" color='red' animation='pulse' height={500} style={{width: '100%'}}/>
            }
            {
                slideStatus == true &&
                (
                    <div className="slide-container">
                        <Slide>
                            {slideList.map((slideImage, index) => (
                                <div className="each-slide" key={index}>
                                    <div style={{
                                        textAlign: 'center',
                                        backgroundColor: slideImage.background_color,
                                        backgroundImage: `url(` + slideImage.image + `)`,
                                        backgroundSize: "cover",
                                        backgroundPosition: "center",
                                        display: "block",
                                        height: "500px"
                                    }}>
                                        <div className=" ">
                                            <div className=" ">
                                                {/*<img src={slideImage.image} style={{width: 'auto', height: '500px'}}/>*/}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slide>
                    </div>
                )
            }
        </div>
    );
}

export default Slides;
