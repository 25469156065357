import React, {useEffect} from "react";
import Axios from "axios";
import {headers, mainUrl} from "../../../../common/common";
import CategoryBody from "./CategoryBody";
import {useLocation} from "react-router";

function CategoryPart() {
    const [categoryList, setCategoryList] = React.useState([]);
    const [categoryDetailList, setCategoryDetailList] = React.useState([]);
    const [categoryListStatus, setCategoryListStatus] = React.useState(false);

    const history = useLocation();
    useEffect(() => {
        LoadMenu();
    }, []);

    function getCategory() {
        // return Axios.get(mainUrl + "getCategorys",{headers});
    }
    function getCategoryDetail() {
        return Axios.post(mainUrl + "getCategoryDetail",
            {
                'category_id': localStorage.getItem("categoryId"),
                'lang':localStorage.getItem("lang")
            } ,{headers});
    }

    const LoadMenu = async () => {
        await Promise.all([getCategory(),getCategoryDetail()])
            .then(function (results) {
                // setCategoryList(results[0].data);
                setCategoryDetailList(results[1].data);
                setCategoryListStatus(true);
            }).catch(function (thrown) {
                if (Axios.isCancel(thrown)) {
                    console.log('Request canceled', thrown.message);
                } else {
                    // handle error
                }
            });
        ;
    }

    return (
        <>
            <CategoryBody
                categoryDetailList={categoryDetailList}
                categoryListStatus={categoryListStatus}
            />
        </>
    );
}

export default CategoryPart;
