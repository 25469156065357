import React ,{useEffect} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Skeleton from "@mui/material/Skeleton";
import SearchIcon from "@mui/icons-material/Search";
import {Spinner} from "react-bootstrap";
import Axios from "axios";
import {headers, mainUrl} from "../../../../common/common";
import CartModel from "../cart/CartModel";
import DetailModel from "./detail/DetailModel";

function Stories() {

    const [storiesListStates, setStoriesListStates] = React.useState(false);
    const [storiesList, setStoriesList] = React.useState(false);
    const [showModel, setShowModel] = React.useState(false);
    const [detail, setDetail] = React.useState("");

    useEffect(() => {
        loadList();
    },[])

    function getStoriesList() {
        return Axios.post(mainUrl + "getStoriesList",{
            'lang':localStorage.getItem("lang")}, {headers});
    }

    const loadList = async () => {
        await Promise.all([getStoriesList()])
            .then(function (results) {
                setStoriesList(results[0].data);
                setStoriesListStates(true);
            }).catch(function (thrown) {
                if (Axios.isCancel(thrown)) {
                    console.log('Request canceled', thrown.message);
                } else {
                }
            });
    }
    return (
        <>
            {
                <>
                    <div className=" ">
                        <DetailModel detail={detail} setShowModel={setShowModel} showModel={showModel}/>
                        <StoriesList storiesListStates={storiesListStates} storiesList={storiesList} setShowModel={setShowModel} setDetail={setDetail}/>
                    </div>
                </>
            }
        </>
    );


    function StoriesList(props) {


        function getStoriesList(id) {
            return Axios.post(mainUrl + "getStoriesDetail",{
                "story_id":id,
                'lang':localStorage.getItem("lang")
            }, {headers});
        }

        const getDetail = async (id) => {
            await Promise.all([getStoriesList(id)])
                .then(function (results) {
                    setDetail(results[0].data[0].detail);
                }).catch(function (thrown) {
                    if (Axios.isCancel(thrown)) {
                        console.log('Request canceled', thrown.message);
                    } else {
                    }
                });
        }
        
        return (
            <>

                {
                    props.storiesListStates == false &&
                    <>
                        <Row className="col-md-10 offset-1" id="headerrtl"
                             style={{display: 'flex', justifyContent: 'center'}}>
                            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16].map((e, index) => (
                                <Col key={index} className="col-md-3 "
                                     style={{padding: '20px', boxShadow: '0px 0px 3px gray', margin: '20px'}}>
                                    <Row>
                                        <Row style={{paddingBottom: '20px'}}>
                                            <Col style={{display: 'flex', justifyContent: 'center'}}>
                                                <Skeleton variant="rectangular" color='red' animation='pulse' style={{}}
                                                          height={350} width={400}/>
                                            </Col>
                                        </Row>
                                        <Row style={{paddingBottom: '20px'}}>
                                            <Col style={{display: 'flex', justifyContent: 'center'}}>
                                                <Skeleton variant="rectangular" color='red' animation='pulse' style={{}}
                                                          height={50} width={400}/>
                                            </Col>
                                        </Row>
                                        <Row style={{paddingBottom: '20px'}}>
                                            <Col >
                                                <Skeleton variant="rectangular" color='red' animation='pulse' style={{}}
                                                          height={25} width={300}/>
                                            </Col>
                                        </Row>
                                    </Row>
                                </Col>
                            ))}
                        </Row>
                    </>
                }

                {
                    props.storiesListStates == true &&
                    <>

                        <Row className="col-md-10 offset-1" id="headerrtl"
                             style={{display: 'flex', marginRight: '0', justifyContent: 'center'}}>
                            {
                                props.storiesList.map((p, index) => (
                                        <Col key={index} className="col-md-3 "
                                             style={{padding: '20px', boxShadow: '0px 0px 3px gray', margin: '20px',minWidth:'400px'}}>
                                            <Row>
                                                <Col>
                                                    {p.image != null &&
                                                    <div role='button' style={{position: "relative", textAlign: 'center'}}
                                                         onClick={async() => {
                                                             await getDetail(p.id);
                                                             props.setShowModel(true);
                                                         }}
                                                    >
                                                        <img src={p.image} height='400' width='auto' style={{position: 'relative'}}/>
                                                    </div>
                                                    }
                                                    {p.image == null &&
                                                    <Skeleton variant="rectangular" color='red'
                                                              animation='pulse' height={400}/>
                                                    }
                                                </Col>

                                            </Row>
                                            <Row className="p-xl-1">
                                                <Col>
                                                    {p.title != null &&
                                                    <span
                                                        className="text-default h6 text-info text-capitalize">{p.title}</span>
                                                    }
                                                    {p.title == null &&
                                                    <Skeleton variant="rectangular" color='red'
                                                              animation='pulse' height={40}/>
                                                    }
                                                </Col>
                                            </Row>
                                            <Row className="p-xl-1">
                                            <Col>
                                                {p.description != null &&
                                                <span
                                                    className="text-default h6 text-capitalize">{p.description}</span>
                                                }
                                                {p.description == null &&
                                                <Skeleton variant="rectangular" color='red'
                                                          animation='pulse' height={40}/>
                                                }
                                            </Col>
                                        </Row>

                                        </Col>
                                    )
                                )
                            }

                        </Row>
                    </>
                }
            </>
        );
    }
}

export default Stories;
