import {Modal} from "react-bootstrap";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import Login from "./Login";
import {useTranslation} from "react-i18next";

function LoginModel(props) {

    const {t, i18n} = useTranslation();
    return (
        <>
            <Modal backdrop={true} show={props.showLoginModel} backdrop="static" size="md" onHide={() => props.setShowLoginModel(false)}
                   style={{top: '10vh'}}>
                <Modal.Header>
                    <div  style={{ right: '10px',display: 'flex',width:'100%' }}>
                        <div style={{whiteSpace:'nowrap'}}>
                            <h2>{t('login')}</h2>
                        </div>
                        <div id="left" style={{textAlign: 'right', width: '100%'}}>
                            <CloseIcon fontSize='large' role="button"
                                       style={{backgroundColor: 'rgb(255,0,0)', borderRadius: '50%', right: '0',color:'rgb(255,255,266)'}}
                                       onClick={() => props.setShowLoginModel(false)}/>
                        </div>
                    </div>

                </Modal.Header>
                <Modal.Body style={{backgroundColor:'rgb(180 184 185 / 50%)'}}>
                    <Login setShowLoginModel={props.setShowLoginModel} setShowRegisterModel={props.setShowRegisterModel}/>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default LoginModel;
