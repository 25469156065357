import React, {useEffect, useState} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {Button, FormGroup} from "react-bootstrap";
import Axios from "axios";
import {headers, mainUrl,webUrl} from "../../../../../common/common";
import toast from "react-hot-toast";
import PhoneInput from "react-phone-number-input";

import 'react-phone-number-input/style.css'
import en from 'react-phone-number-input/locale/en'
import {Input} from "reactstrap";
import {padding} from "@mui/system";
import {useTranslation} from "react-i18next";

function Login(props) {
    const [verifyStatus, setVerifyStatus] = useState(false);
    const [email, setEmail] = useState("");
    const sendVerfication = async () => {
        // cursor: wait
        document.getElementById("buttonSend").disabled=true;
        document.getElementById("forms").style.cursor="wait";
        await Axios.post(mainUrl + "verifyAccount", {
            email:email
        }, {headers}).then(res => {
            notifySuccess('Please verify your account');
            setVerifyStatus(false);
            document.getElementById("forms").style.cursor=null;
            document.getElementById("buttonSend").disabled=false;
        }).catch(function (error) {
            notifyError(error.response.data.error);
        });
    }
    const [passwordStatus,setPasswordStatus]=useState(false)
    const  hideshowpassword=()=> {
        var x = document.getElementById("password");
        if (x.type === "password") {
            x.type = "text";
            setPasswordStatus(false);
        } else {
            x.type = "password";
            setPasswordStatus(true);
        }
    }

    const submitForm = async (e) => {
        e.preventDefault();
        const f = e.target;
        await Axios.post(mainUrl + "loginCustomer", {
            email: f.email.value,
            password: f.password.value
        }, {headers}).then(res => {
            localStorage.setItem("token", JSON.stringify(res.data));
            // localStorage.setItem("token", JSON.stringify(res.data));
            notifySuccess('Successfully logon to made in Afghanistan');
            props.setShowLoginModel(false);
        }).catch(function (error) {
            console.error(error);
            if (error.response.status == 409) {
                setVerifyStatus(true);
                setEmail(f.email.value);
            }
            notifyError(error.response.data.error);
        });
    };
    const notifyError = (message) => toast.error(message, {
        duration: 6000,
    });
    const notifySuccess = (message) => toast.success(message, {
        duration: 6000,
    });
    const forgotUrl = webUrl + "userChangePassword";

    const {t, i18n} = useTranslation();
    return (
        <>
            <form onSubmit={(e) => submitForm(e)}>
                <Row id="forms">
                    <Col className="col-md-12   pr-3 pl-3">
                        <Row>
                            <Input name="id" id="id" type="hidden"/>
                            <Col md="12">
                                <div className="form-group">
                                    <label>  {t('email')}<span className="text text-danger">*</span></label>
                                    <Input className="w-100" required name="email" id="email" required
                                           placeholder= {t('emailplace')}
                                           title="Email" type="email"/>
                                </div>
                            </Col>
                        </Row>
                        {verifyStatus &&
                        <Row>
                            <Col>
                                <div className="bg-opacity-25 bg-dark p-2 justify-content-around">
                                    <span className="bg-warning">
                                        {t('ver1')}
                                        {/*Your email is not verified!*/}

                                    </span>
                                    <br/>
                                    {t('ver2')}
                                    {/*please verify your email address.*/}
                                    <br/>
                                    {t('ver3')}
                                    {/*we have sent the verification mail to*/}
                                    <span style={{color: "blue"}}>{email}</span>.
                                    {t('ver4')}
                                    {/*if you can not find the email verification mail in the index folder. Please check the Junk/Spam folder.*/}
                                    <br/>
                                    {t('ver5')}
                                    {/*if you did not receive the email verification mail please click on the resend button.*/}

                                    <button id="buttonSend" style={{
                                        backgroundColor: "gray",
                                        paddingLeft: '2px',
                                        paddingRight: '2px',
                                        borderRadius: "10px"
                                    }} onClick={() => {
                                        sendVerfication();
                                    }}>Resend Verification Mail</button>
                                </div>
                            </Col>
                        </Row>
                        }
                        <Row>
                            <Col className="pr-3" md="12">
                                <div className="form-group">
                                    <label>{t('password')} </label>

                                    <div className="w-100 bg-light"
                                         style={{display: 'flex',borderRadius:'5px'}}>
                                        <div style={{width: '100%'}}>
                                            <input   required style={{border: '0px solid'}} name="password" id="password"
                                                     type="password"
                                                   className="w-100 form-control "
                                                   title={t('password')}
                                                   placeholder={t('password')}
                                            />
                                        </div>
                                        <div  style={{padding:'10px'}} onClick={(e)=>hideshowpassword()}  role="button">
                                            {passwordStatus==true &&
                                            <i className="fa fa-eye" />
                                            }
                                            {passwordStatus==false &&
                                            <i className="fa fa-eye-slash" />
                                            }
                                        </div>
                                    </div>
                                </div>

                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col className="text-center">
                        {/*#454444*/}
                        <Button className="btn btn-primary " style={{backgroundColor: 'rgb(29 169 225)',width:'100%'}} type="submit">
                            {/*Login*/}
                            {t('loginbutton')}
                        </Button>
                        <br/>
                        <span style={{textAlign: 'left!important', padding:'20px'}}>
                        {t('logindetail')}
                        <br/>
                        </span>

                        <a className="badge badge-danger " title="forgot Password" style={{ margin:'20px'}} target="_blank"
                           href={forgotUrl}>
                            {/*Forgot Password*/}
                            {t('fpassword')}
                        </a>
                        <hr/>

                        <div>{t('newmade')} </div>
                        <div role="button"
                             onClick={() =>{props.setShowLoginModel(false);
                                        props.setShowRegisterModel(true);}}
                             className="badge badge-default " title="forgot Password" style={{ margin:'20px'}} target="_blank"
                           href={forgotUrl}>
                            {/*Forgot Password*/}
                            {t('createmade')}
                        </div>
                    </Col>
                </Row>
            </form>
        </>
    );
}

export default Login;
